<template>
  <v-card :loading="isLoading" :disabled="isDisabled" max-width="700" min-width="300">
    <v-toolbar
        flat
        class="blue lighten-2">
      <v-icon color="white">mdi-paw</v-icon>
      <v-toolbar-title class="white--text">
        Mesajlar
      </v-toolbar-title>
    </v-toolbar>

    <virtual-list style="height: 600px; overflow-y: auto;"
                  class="mb-1 pa-3 "
                  :data-key="'id'"
                  :extra-props={userId:this.userId}
                  :data-sources="messages"
                  :data-component="itemComponent"/>

    <v-card-actions class="align-start mb-1 pa-3">

      <v-textarea
          outlined
          name="message"
          class="mr-4"
          label="Mesajınızı yazınız..."
          hint="Mesajınızı yazarken haklarınızın sınırlı olduğunu unutmayınız."
          no-resize
          height="150"
          counter="500"
          v-model.trim="messageTextArea"
      ></v-textarea>

      <v-btn
          :disabled="isSendDisabled"
          :style="disableSendStyle"
          color="primary"
          dark
          height="150"
          @click="sendMessage()">
        <v-icon>mdi-send</v-icon>
      </v-btn>

    </v-card-actions>
  </v-card>

</template>

<script>
import MessageCard from "@/pages/common/components/MessageCard";
import VirtualList from 'vue-virtual-scroll-list'
import axios from "axios";

export default {
  name: "ConversationCard",
  components: {VirtualList},
  props: ['userId', 'appointmentId'],
  data() {
    return {
      messages: [],
      messageTextArea: '',
      isLoading: false,
      isDisabled: false,
      itemComponent: MessageCard,
    }
  },
  created() {
    this.getConversationHistory();
  },
  computed: {
    isSendDisabled() {
      return this.messageTextArea.length === 0;
    },
    disableSendStyle() {
      return this.isSendDisabled ? "background-color: grey !important;" : "";
    }
  },
  methods: {
    async getConversationHistory() {
      console.log('getConversationHistory')
      this.isLoading = true;
      this.isDisabled = true;

      const url = process.env.VUE_APP_BASE_URL + '/message/getConversation';

      const data = {
        appointmentId: this.appointmentId,
      };

      axios.post(url, data).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Mesaj bilgilerine erisilemedi.'
          );
        }

        this.messages = responseData.messages;
        this.isLoading = false;
        this.isDisabled = false;

      });
    },
    async sendMessage() {
      console.log('sendMessage')
      this.isLoading = true;
      this.isDisabled = true;

      const url = process.env.VUE_APP_BASE_URL + '/message/sendMessage';

      const data = {
        appointmentId: this.appointmentId,
        messageText: this.messageTextArea
      };

      axios.post(url, data).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Mesaj bilgilerine erisilemedi.'
          );
        }

        if (responseData.result.success === false) {
          this.errorText = responseData.result.message;
          return;
        }

        this.messageTextArea = '';

        this.getConversationHistory();

      });

    }
  },
}
</script>

<style scoped>
</style>