<template>
    <v-card max-width="800" min-width="400">
      <v-toolbar
          flat
          class="blue lighten-2">
        <v-icon color="white">mdi-paw</v-icon>
        <v-toolbar-title class="white--text">
          Hasta Bilgileri
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="text-no-wrap">
          <v-col>
            <p class="body-1"><strong>Adı:</strong> {{ patient.name }}</p>
          </v-col>
          <v-col>
            <p class="body-1"><strong>Türü:</strong> {{ patient.species }}</p>
          </v-col>
          <v-col>
            <p class="body-1"><strong>Doğum Tarihi:</strong> {{ patient.birthDate }}</p>
        </v-col>
      </v-row>
      <v-row class="text-no-wrap">
        <v-col>
          <p class="body-1"><strong>Irkı:</strong> {{ patient.breed }}</p>
        </v-col>
        <v-col>
          <p class="body-1"><strong>Cinsiyeti:</strong> {{ patient.sex }}</p>
        </v-col>
        <v-col>
          <p class="body-1"><strong>Kısırlaştırma Durumu:</strong> {{ patient.sterility }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="body-1"><strong>Vaka Başlığı:</strong> {{ patient.title }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p class="body-1 font-weight-bold">Anamnez</p>
          <p class="body-2">{{ patient.anamnesis }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p class="body-1 font-weight-bold">Klinik Muayene Bulguları</p>
          <p class="body-2">{{ patient.examinationNotes }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p class="body-1 font-weight-bold">Danışanın Vakaya İlişkin Görüşleri ve Soruları</p>
          <p class="body-2">{{ patient.extraInfo }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p class="body-1 font-weight-bold">Vakaya İlişkin Danışan Tarafından Eklenen Belgeler</p>
          <div v-for="file in patient.attachments" :key="file.link">
            <v-btn icon :href="file.link">
              <v-icon>mdi-file-download</v-icon>
            </v-btn>
            {{ file.note }}
          </div>
        </v-col>
      </v-row>

      </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: "PatientInfo",
  props: {
    patient: {
      type: Object,
      default() {
        return {}
      }
    }
  },
}
</script>

<style scoped>

</style>