<template>
  <section>
    <v-row>
      <v-col cols="5">
        <v-alert v-if="alert.text" border="bottom"
                 :color="alert.color" dark type="warning"
                 elevation="2">{{ alert.text }}
        </v-alert>
      </v-col>
    </v-row>

    <appointment-detail @updateOverlay="updateOverlay" :appointment="appointment"
                        :appointment-id="appointmentId"></appointment-detail>
    <progress-overlay :is-loading="isLoading"></progress-overlay>
  </section>
</template>

<script>
import AppointmentDetail from "@/pages/common/components/AppointmentDetail";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import axios from "axios";

export default {
  name: "PractitionerAppointmentDetail",
  components: {ProgressOverlay, AppointmentDetail},
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    this.appointmentId = urlParams.get('appointmentId');

    this.getAppointmentDetails();
  },
  data() {
    return {
      alert: {
        text: '',
        color: 'yellow darken-2'
      },
      appointmentId: null,
      isLoading: true,
      appointment: {
        patient: {
          name: '',
          examinationNotes: '',
          anamnesis: '',
          breed: '',
          birthDate: '',
        },
        time: '',
        clinicName: '',
        practitionerFullName: '',
        status: ''
      },
    }
  },
  methods: {
    updateOverlay(isEnabled) {
      this.isLoading = isEnabled;
    },
    async getAppointmentDetails() {
      console.log('getAppointmentDetails')
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/appointment/getAppointmentDetail?appointmentId=' + this.appointmentId;

      axios.get(url).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Kullanici bilgilerine erisilemedi.'
          );
        }

        this.appointment = responseData.appointmentDetailResponse;
        this.updateOverlay(false);
        this.updateAlert();

      });


    },
    updateAlert() {
      console.log('update alert')
      console.log(this.appointment)
      if (this.appointment.status === 'CREATED') {
        this.alert = {
          text: 'Vaka kaydınız onay beklemektedir. Seçtiğiniz uzman hekim en geç 24 saat içerisinde talebinize yanıt verecektir.',
          color: 'blue lighten-2'
        }
      } else if (this.appointment.status === 'FOLLOWUP') {
        this.alert = {
          text: 'Vaka görüşmesi tamamlandı. Hasta takibi için sorularınızı mesajlar kısmından uzman hekime iletebilirsiniz.',
          color: 'blue darken-2'
        }
      } else if (this.appointment.status === 'DECLINED') {
        this.alert = {
          text: 'Vaka kaydınız uzman tarafından reddedilmiştir.',
          color: 'yellow darken-2'
        }
      } else if (this.appointment.status === 'CLOSED') {
        this.alert = {
          text: 'Vaka kaydınız tamamlanmıştır.',
          color: 'yellow darken-2'
        }
      } else {
        this.alert = {}
      }
    }
  },
}
</script>

<style scoped>

</style>