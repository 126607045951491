<template>
  <v-alert class="mx-auto text-no-wrap ma-1" dense v-if="alertText" border="bottom"
           color="yellow darken-2" dark type="error" dismissible
           elevation="2">{{ alertText }}
  </v-alert>
</template>

<script>
export default {
  name: "AnnouncementAlert",
  data() {
    return {
      userStatus: '',
      alertText: null,
    }
  },
  created() {
    this.userStatus = localStorage.getItem('userStatus');
    this.updateAlertText();
  },
  methods: {
    updateAlertText() {
      if (this.userStatus === "MISSING_INFO") {
        this.alertText = "Hesabınızın aktifleştirilmesi için lütfen profil bilgilerinizi tamamlayınız."
      } else if (this.userStatus === "AWAITING_APPROVAL") {
        this.alertText = "Hesabınız inceleme aşamasında olup en kısa sürede değerlendirilecektir."
      }
    }
  },
}
</script>

<style scoped>

</style>