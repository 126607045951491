<template>
  <v-flex class="flex-fill pa-10">
    <v-expand-transition>
      <v-alert width="400px" class="mx-auto" v-if="errorText" border="bottom" color="pink darken-1" dark type="error"
               elevation="2">{{ errorText }}
      </v-alert>
    </v-expand-transition>
    <v-layout justify-center align-center>
      <v-card elevation="2" width="300px">
        <v-toolbar
            flat
            class="blue lighten-2">
          <v-icon color="white">mdi-account</v-icon>
          <v-toolbar-title class="white--text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-form @submit.prevent="submitLogin">

          <v-card-text class="pa-3">

            <v-text-field label="E-Posta"
                          placeholder=" "
                          type="email"
                          id="email"
                          v-model.trim="email"
                          @focusout="clearAlerts"/>
            <v-text-field label="Şifre"
                          placeholder=" "
                          :type="showPassword ? 'text' : 'password'"
                          id="password"
                          v-model.trim="password"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="showPassword = !showPassword"
                          @focusout="clearAlerts"/>

          </v-card-text>

          <v-card-actions class="pa-3">
            <v-btn :to="signupLink">KAYIT OL</v-btn>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="primary">
              GİRİŞ
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-divider></v-divider>

        <router-link class="d-flex justify-center pb-3 pt-3" to="/forgotPassword">Şifremi Unuttum
        </router-link>

        <v-divider></v-divider>

        <router-link class="d-flex justify-center pb-3 pt-3" :to="switchUserTypeBtnLink">{{
            switchUserTypeBtnText
          }}
        </router-link>

      </v-card>

    </v-layout>
  </v-flex>
</template>

<script>
import router from "@/router";
import vueCookies from "vue-cookies";
export default {
  name: "Login",
  props:['isSpecialist'],
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      errorText: '',
      switchUserTypeBtnText:'',
      switchUserTypeBtnLink:'',
      userType:'',
      signupLink:'',
      title:'',
      redirectPath: '',
    }
  },
  created() {

    if (this.isSpecialist) {
      this.switchUserTypeBtnText = 'Klinisyen Giriş';
      this.userType = 'SPECIALIST'
      this.signupLink = '/sp/signup'
      this.switchUserTypeBtnLink = '/pr/login'
      this.title = 'Uzman Giriş'
      this.redirectPath = '/sp/dashboard'
    } else {
      this.switchUserTypeBtnText = 'Uzman Giriş';
      this.userType = 'PRACTITIONER'
      this.signupLink = '/pr/signup'
      this.switchUserTypeBtnLink = '/sp/login'
      this.title = 'Pratisyen Giriş'
      this.redirectPath = '/pr/dashboard'
    }

    const token = vueCookies.get("token");
    if(token != null){
      if (this.isSpecialist) {
        router.replace('/sp/dashboard')
      } else {
        router.replace('/pr/dashboard')
      }
    }
  },
  methods: {
    async submitLogin() {
      console.log('submitLogin')

      const loginPayload = {
        email: this.email,
        password: this.password,
      };

      if (loginPayload.email === null || loginPayload.email.length < 6
          || loginPayload.password === null || loginPayload.password.length < 6) {
        this.errorText = 'Lütfen kullanıcı adı ve şifrenizi giriniz.'
        return;
      }

      const url = process.env.VUE_APP_BASE_URL + '/login'

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'userType': this.userType
        },
        body: JSON.stringify({
          email: loginPayload.email,
          password: loginPayload.password
        })
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(
            responseData.message || 'Hatalı kullanıcı adı veya şifre. Lütfen bilgilerinizi kontrol ediniz.'
        );
      }

      if (responseData.result.success === false) {
        this.errorText = responseData.result.message;
        return;
      }

      vueCookies.set("token",responseData.token,"1d");

      localStorage.setItem("usertype", this.userType);
      localStorage.setItem("profilePhoto", responseData.profilePhotoLink);
      localStorage.setItem("userStatus", responseData.userStatus);
      localStorage.setItem("fullName", responseData.fullName);
      localStorage.setItem("userId", responseData.userId);

      this.$store.commit('auth/setNeededActions', {
        calendarNeedsAction: responseData.calendarNeedsAction,
        profileNeedsAction: responseData.profileNeedsAction,
        settingsNeedsAction: responseData.settingsNeedsAction,
        paymentsNeedsAction: responseData.paymentsNeedsAction,
      });

      router.replace(this.redirectPath);

    },
    clearAlerts() {
      this.errorText = null;
    }
  },
}
</script>

<style scoped>

</style>