<template>
  <section disabled>
  <v-row>
    <v-col>
      <v-card max-width="800" min-width="300" class="mb-4">
        <v-toolbar
            flat
            class="blue lighten-2">
          <v-icon color="white">mdi-account</v-icon>
          <v-toolbar-title class="white--text">
            Danışanın Bilgileri
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <p><strong>Danışan:</strong> {{ appointment.practitionerFullName }}</p>
            </v-col>
            <v-col>
              <p><strong>Danışanın Kliniği:</strong> {{ appointment.clinicName }}</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-4" max-width="800" min-width="300" >
        <v-toolbar
            flat
            class="blue lighten-2">
          <v-icon color="white">mdi-calendar</v-icon>
          <v-toolbar-title class="white--text">
            Randevu Bilgileri
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <p><strong>Randevu Tarihi:</strong> {{ date }}</p>
            </v-col>
            <v-col>
              <p><strong>Randevu Saati:</strong> {{ time }}</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <patient-info :patient="appointment.patient"></patient-info>
    </v-col>
  </v-row>

  </section>
</template>

<script>

import PatientInfo from "@/pages/common/components/PatientInfo";

export default {
  name: "AppointmentDetail",
  components: {PatientInfo},
  props: ['appointmentId', 'appointment'],
  computed: {
    time() {
      const dateTime = this.appointment.time.split('T');
      return dateTime[1];
    },
    date() {
      const dateTime = this.appointment.time.split('T');
      return dateTime[0];
    }
  },
}
</script>

<style scoped>

</style>