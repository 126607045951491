<template>
  <v-row>
    <v-col cols="4">
      <v-date-picker
          show-current
          elevation="2"
          :allowed-dates="isDateAvailable"
          v-model="selectedDate"
          first-day-of-week="1"
          color="green lighten-1"
          locale="tr"
          :min="minDate"
          :max="maxDate"
          @input="getSpecialistAvailability"
      ></v-date-picker>

    </v-col>
    <v-col class="align-self-center">

      <v-item-group v-if="!availabilitiesLoading"
                    v-model="selectedTime"
                    active-class="primary"
                    @change="updateAppointmentTime"
                    mandatory>
        <v-container>
          <v-row>
            <div
                v-for="slot in availableHours"
                :key="slot.time">
              <v-item v-slot="{ active, toggle }" :value="slot.time">
                <v-btn
                    class="d-flex align-center ma-2"
                    :value="slot.time"
                    @click="toggle">
                  {{ slot.text }}
                </v-btn>
              </v-item>
            </div>
          </v-row>
        </v-container>
      </v-item-group>
      <v-progress-circular v-else>
      </v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "AppointmentTimePicker",
  props: ['specialistId'],
  data() {
    return {
      selectedDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      availabilitiesLoading: false,
      availableHours: [],
      selectedTime: null,
      availableDaysLoading: false,
      availableDates: []
    }
  },
  computed: {
    minDate() {
      const minDate = new Date();
      minDate.setDate(minDate.getDate() + 1);
      return minDate.toISOString();
    },
    maxDate() {
      const maxDate = new Date();
      maxDate.setDate(maxDate.getDate() + 14);
      return maxDate.toISOString();
    },
  },
  watch: {
    specialistId(newValue, oldValue) {
      if (newValue == null || oldValue === newValue) {
        return;
      }
      this.getAvailableDays();
    }
  },
  methods: {
    isDateAvailable(date) {
      return this.availableDates.find(d => {
        return d === date
      })
    },
    async getAvailableDays() {
      console.log('getAvailableDays');

      this.availableDaysLoading = true;
      const url = process.env.VUE_APP_BASE_URL + '/practitioner/appointment/getAvailableDays'

      const data = {
        specialistId: this.specialistId,
      };

      axios.post(url, data).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Musaitlik bulunan günlere erişilemedi.'
          );
        }

        this.availableDates = responseData.availableDates;
        this.availableDaysLoading = false;
      })

    },
    async getSpecialistAvailability(date) {
      console.log('getSpecialistAvailability');
      console.log(date);

      this.availabilitiesLoading = true;
      const url = process.env.VUE_APP_BASE_URL + '/practitioner/appointment/getSpecialistSlotsForDay'

      const data = {
        specialistId: this.specialistId,
        date: date,
      };

      axios.post(url, data).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Musaitlik bulunan saatlere erişilemedi.'
          );
        }

        this.availableHours = responseData.slotAvailabilities;
        this.availabilitiesLoading = false;
      });

    },
    updateAppointmentTime() {
      console.log('emmit updateAppointmentTime')
      console.log(this.selectedTime)
      this.$emit("updateAppointmentTime", this.selectedTime);

    }
  }
}
</script>

<style scoped>

</style>