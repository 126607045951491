<template>
  <section>
    <v-expand-transition>
      <v-alert width="400px" class="mx-auto" v-if="errorMessage" border="bottom"
               color="red"
               type="error"
               elevation="2">{{ errorMessage }}
      </v-alert>
    </v-expand-transition>
    <v-stepper name='createAppointmentCard '
               elevation="2" width="1000px"
               outlined class="mx-auto pa-3"
               v-model="step">
      <v-stepper-header>
        <v-stepper-step
            :complete="step > 1"
            step="1">
          Uzmanı Seçin
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="step > 2"
            step="2">
          Tarih ve Saat Seçin
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="step > 3"
            step="3">
          Vaka Bilgilerini Girin
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
            :complete="step > 4"
            step="4">
          Tahlil Sonuçları
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
            :complete="step > 5"
            step="5">
          Ödeme
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="6">
          Sonuç
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form @submit.prevent="step = 2">
            <v-autocomplete
                ref="fields"
                v-model="fieldId"
                :items="fields"
                item-text="name"
                item-value="id"
                label="Danışmanlık talep ettiğiniz klinik alanı seçiniz"
                @change="getSpecialists"
                required
            ></v-autocomplete>
            <v-autocomplete
                ref="specialists"
                v-model="specialist"
                :items="specialists"
                item-text="name"
                :loading="specialistsLoading"
                label="Danışmanlık hizmeti almak istediğiniz uzmanı seçiniz"
                no-data-text="Bu alanda hizmet veren bir uzman bulunmamaktadır."
                @change="updateSpecialistId"
                return-object
                required
            ></v-autocomplete>
            <specialist-info-card v-if='specialist.id' :specialist="specialist"></specialist-info-card>
            <v-btn
                :disabled="specialist.id == null"
                class="float-right"
                color="primary"
                type="submit">
              İLERİ
            </v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">

          <appointment-time-picker :specialist-id="this.appointment.specialistId"
                                   @updateAppointmentTime="updateAppointmentTime"></appointment-time-picker>

          <v-row>
            <v-col>
              <v-btn
                  :disabled="appointment.time == null"
                  color="primary"
                  class="float-right"
                  @click="step = 3">
                İLERİ
              </v-btn>

              <v-btn text
                     class="float-left"
                     @click="step = 1">
                GERİ
              </v-btn>
            </v-col>
          </v-row>

        </v-stepper-content>

        <v-stepper-content step="3">

          <v-row>
            <v-col>
              <p><strong>Uyarı:</strong> Bu aşamada gireceğiniz tüm
                bilgiler danışacağınız uzmana hastanız hakkında fikir verecektir.
                Uzman hekimi doğru yönlendirmek adına lütfen hastanız ile ilgili doğru,
                detaylı ve eksiksiz bilgi vermeye özen gösteriniz. </p>
            </v-col>
          </v-row>
          <v-row>

            <v-col>
              <v-text-field label="Hasta Sahibinin İsmi"
                            type="text"
                            id="ownerName"
                            :rules="notEmpty"
                            v-model.trim="appointment.patient.ownersName"/>
              <v-text-field label="Hastanın İsmi" type="text" id="patientName" v-model.trim="appointment.patient.name"/>
              <v-select
                  label="Hastanın Türü"
                  item-text="text"
                  item-value="id"
                  :items="speciesList"
                  v-model="appointment.patient.species"
              ></v-select>
              <v-text-field label="Hastanın Irkı" type="text" id="patientBreed"
                            v-model.trim="appointment.patient.breed"/>

              <v-text-field label="Hastanın Doğum Tarihi" type="date" id="patientDob"
                            v-model.trim="appointment.patient.birthDate"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Vaka Başlığı" type="text" id="appointmentTitle"
                            hint="Ör: 4 gündür kusma ve halsizlik"
                            :rules="notEmpty"
                            v-model.trim="appointment.patient.title"/>

              <v-radio-group
                  v-model="appointment.patient.sex">
                <template v-slot:label>
                  <div>Hastanın Cinsiyeti</div>
                </template>
                <v-radio
                    label="Erkek"
                    value="MALE"
                ></v-radio>
                <v-radio
                    label="Dişi"
                    value="FEMALE"
                ></v-radio>
              </v-radio-group>
              <v-radio-group
                  v-model="appointment.patient.sterility">
                <template v-slot:label>
                  <div>Hastanın Kısırlaştırma Durumu</div>
                </template>
                <v-radio
                    label="Kısır"
                    value="STERILE"
                ></v-radio>
                <v-radio
                    label="Kısır Değil"
                    value="NONSTERILE"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                  outlined
                  name="anamnesis"
                  label="Anamnez Bilgileri"
                  class="ma-2"
                  :rules="notEmpty"
                  hint="Hastanın anamnez bilgilerini detaylı bir şekilde giriniz."
                  no-resize
                  counter="500"
                  v-model="appointment.patient.anamnesis"
              ></v-textarea>
              <v-textarea
                  outlined
                  name="examination"
                  label="Klinik Muayene Bilgileri"
                  class="ma-2"
                  :rules="notEmpty"
                  counter="500"
                  hint="Hastanın klinik muayene bilgilerini detaylı bir şekilde giriniz."
                  no-resize
                  v-model="appointment.patient.examinationNotes"
              ></v-textarea>

              <v-textarea
                  outlined
                  name="examination"
                  label="Ek Bilgiler ve Sorular"
                  class="ma-2"
                  counter="500"
                  hint="Vakanız ile ilgili gerekli ek bilgileri, vaka hakkındaki görüşlerinizi, sormak istediklerinizi ekleyin."
                  no-resize
                  v-model="appointment.patient.extraInfo"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                  :disabled="!isPatientInfoEntered"
                  color="primary"
                  class="float-right"
                  @click="step = 4">
                İLERİ
              </v-btn>

              <v-btn text
                     class="float-left"
                     @click="step = 2">
                GERİ
              </v-btn>
            </v-col>
          </v-row>

        </v-stepper-content>

        <v-stepper-content step="4">
          <v-row>
            <v-col>
              <file-upload @updateAttachments="updateAttachments"
                           :attachments="appointment.patient.attachments"
              ></file-upload>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                  color="primary"
                  class="float-right"
                  @click="step = 5">
                İLERİ
              </v-btn>

              <v-btn text
                     class="float-left"
                     @click="step = 3">
                GERİ
              </v-btn>
            </v-col>
          </v-row>

        </v-stepper-content>

        <v-stepper-content step="5">
          <v-row>
            <v-col justify-center align-center>
              <div>Appointment summary</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                  color="primary"
                  class="float-right"
                  @click="createAppointmentAndGetPaymentForm">
                ÖDEME YAP ve VAKAYI OLUŞTUR
              </v-btn>

              <v-btn text
                     class="float-left"
                     @click="step = 4">
                GERİ
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="6">
          <v-row>
            <v-col justify-center align-center>
              <div>{{resultPageText}}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                  color="primary"
                  class="float-right"
                  @click="redirectToAppointments"
              >
                VAKALARIM
              </v-btn>

            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <progress-overlay :is-loading="isLoading" text="Vaka kaydınız oluşturuluyor."></progress-overlay>
    <result-dialog :result='result' :is-visible="isDialogVisible" @okayClicked="closeDialogAndRedirect"></result-dialog>
  </section>
</template>

<script>
import SpecialistInfoCard from "@/pages/practitioner/components/SpecialistInfoCard";
import FileUpload from "@/pages/practitioner/components/appointment/FileUpload";
import AppointmentTimePicker from "@/pages/practitioner/components/appointment/AppointmentTimePicker";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import ResultDialog from "@/pages/practitioner/components/common/ResultDialog";
import axios from "axios";
import vueCookies from 'vue-cookies'

export default {
  name: "CreateAppointment",
  components: {ResultDialog, ProgressOverlay, AppointmentTimePicker, FileUpload, SpecialistInfoCard},
  data() {
    return {
      result: {
        message: '',
        success: false,
        paymentUrl: ''
      },
      errorMessage:'',
      conversationId: null,
      resultPageText:'',
      isDialogVisible: false,
      isLoading: false,
      step: 1,
      fieldId: '',
      fields: [{id: 'OBSTETRICS', name: 'Doğum ve Jinekoloji'}, {id: 'SURGERY', name: 'Cerrahi'}, {
        id: 'INTERNAL',
        name: 'İç hastalıkları'
      }],
      specialist: {},
      specialists: [],
      specialistsLoading: false,
      speciesList: [{text: "Kedi", id: "CAT"}, {text: "Köpek", id: "DOG"}],
      appointment: {
        time: null,
        specialistId: null,
        patient: {
          title: null,
          ownersName: null,
          name: null,
          breed: null,
          sex: null,
          sterility: null,
          species: null,
          birthDate: null,
          anamnesis: null,
          examinationNotes: null,
          extraInfo: null,
          attachments: []
        }
      },
      notEmpty: [
        v => !!v || 'Lütfen bu alanı doldurunuz.',
      ],
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    this.conversationId = urlParams.get('conversationId');

    if (this.conversationId !== null) {
      this.step = 6;
      this.getPaymentStatus();
    }
  },
  computed: {
    isPatientInfoEntered() {
      return this.appointment.patient.title
          && this.appointment.patient.ownersName
          && this.appointment.patient.name
          && this.appointment.patient.breed
          && this.appointment.patient.sex
          && this.appointment.patient.sterility
          && this.appointment.patient.species
          && this.appointment.patient.birthDate
          && this.appointment.patient.anamnesis
          && this.appointment.patient.examinationNotes
    }
  },
  methods: {
    closeDialogAndRedirect() {
      this.isDialogVisible = false;
      this.$router.replace('appointments')
    },
    updateAppointmentTime(time) {
      this.appointment.time = time;
    },
    updateSpecialistId() {
      this.appointment.specialistId = this.specialist.id
    },
    redirectToAppointments() {
      this.$router.replace('appointments')
    },
    async getPaymentStatus() {
      this.specialistsLoading = true;
      const url = process.env.VUE_APP_BASE_URL + '/practitioner/appointment/getPaymentStatus?conversationId=' + this.conversationId;
      const token = vueCookies.get("token");

      const headersList = new Headers();
      headersList.append("Authorization", "Bearer " + token);
      headersList.append('Content-Type', 'application/json')
      headersList.append("Access-Control-Allow-Origin", "*")

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Ödeme sonucu bilgisine erişilemedi.'
          );
        }

        if(responseData.result.success){
          this.resultPageText = 'Odeme Basariyla alinmis, randevunuz olusturulmustur.'
        }
        this.isLoading = false;
      }).catch(err =>
          console.log(err)
      )

    },
    async getSpecialists(fieldId) {
      console.log('getSpecialists');
      console.log(fieldId);

      this.specialistsLoading = true;
      const url = process.env.VUE_APP_BASE_URL + '/practitioner/appointment/getActiveSpecialistsByField'
      const token = vueCookies.get("token");

      const headersList = new Headers();
      headersList.append("Authorization", "Bearer " + token);
      headersList.append('Content-Type', 'application/json')
      headersList.append("Access-Control-Allow-Origin", "*")

      const data = {
        specialityField: fieldId
      };

      axios.post(url, data).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Uzmanlarin bilgilerine erişilemedi.'
          );
        }

        const specialists = responseData.specialists;

        const specialistsArray = [];

        specialists.forEach((specialist) => {
          specialistsArray.push({
            name: specialist.name + ' ' + specialist.lastname,
            id: specialist.id,
            school: specialist.specialityGraduationUniversity,
            price: specialist.price,
            bio: specialist.bio,
            degree: specialist.specialityDegree,
            speciality: specialist.speciality,
            profilePhoto: specialist.profilePhoto
          })
        });

        this.specialists = specialistsArray;
        this.specialist = {};
        this.specialistsLoading = false;

      });

    },
    async createAppointmentAndGetPaymentForm() {
      console.log('createAppointmentAndGetPaymentForm')
      console.log(this.appointment)
      this.isLoading = true;

      const url = process.env.VUE_APP_BASE_URL + '/practitioner/appointment/createAppointment'

      axios.post(url, this.appointment).then(response => {

        const responseData = response.data;

        if (response.status !== 200 || responseData.result.success === false) {
          this.isLoading=false;
          this.errorMessage = responseData.result.message || 'Vaka kaydi olusturulamadi.'
          window.setInterval(() => {
            this.errorMessage = null;
          }, 3000)
        }

        window.location.href = responseData.paymentUrl;

      });

    },
    updateAttachments(attachments) {
      this.appointment.patient.attachments = attachments;
    }
  },
}
</script>

<style scoped>

</style>