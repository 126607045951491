<template>
  <div>
      <div align="center" v-if="videoList.length === 0">
        <v-progress-circular
            indeterminate
            size="128"
            :width="7"
            color="light-blue"
        ></v-progress-circular>
        <h3>Görüntülü konuşma başlatılıyor. Lütfen kamera ve mikrofon için izin veriniz...</h3>
      </div>

      <v-row v-else class="flex-nowrap">

        <v-col v-for="item in videoList"
               v-bind:video="item"
               v-bind:key="item.id"
               align="center"
               class="align-center align-content-center">

          <v-row class="justify-center">
            <v-sheet align="center" color="grey" rounded
                     class="align-content-center align-center justify-center pr-4 pl-4 pt-4 pb-3 elevation-2">
              <video class="align-content-center align-center justify-center" autoplay playsinline ref="videos"
                     :muted="item.muted" :src="item.stream" :id="item.id"></video>
            </v-sheet>
          </v-row>
          <v-row class="justify-center" v-if="item.isLocal">

            <v-sheet color="blue" rounded class="ma-2 elevation-2">
              <v-btn icon color="white" @click="toggleLocalAudio">
                <v-icon>{{ micIcon }}</v-icon>
              </v-btn>
              <v-menu v-if="availableMicrophones.length > 1"
                      top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      icon>
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-subheader>Mikrofonlar</v-subheader>
                  <v-list-item-group
                      v-model="selectedMic"
                      color="primary"
                  >
                    <v-list-item
                        v-for="item in availableMicrophones"
                        :key="item.deviceId"
                        :value="item.deviceId">
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-sheet>

            <v-sheet color="blue" rounded class="ma-2 elevation-2">
              <v-btn icon color="white" @click="toggleLocalVideo">
                <v-icon>{{ cameraIcon }}</v-icon>
              </v-btn>
              <v-menu v-if="availableCameras.length > 1"
                      top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      icon>
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group
                      v-model="selectedCamera"
                      color="primary"
                  >
                    <v-subheader>Kameralar</v-subheader>
                    <v-list-item
                        v-for="item in availableCameras"
                        :key="item.deviceId"
                        :value="item.deviceId">
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-sheet>
          </v-row>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import {io} from "socket.io-client";

const SimpleSignalClient = require('simple-signal-client');

export default {
  name: 'VueWebrtc',
  components: {},
  data() {
    return {
      signalClient: null,
      videoList: [],
      canvas: null,
      socket: null,
      cameraHeight: 400,
      width: '100%',
      enableLogs: true,
      enableVideo: true,
      enableAudio: true,
      localStream: null,
      availableCameras: [],
      availableMicrophones: [],
      selectedMic: null,
      selectedCamera: null,
      peerConnections: []
    };
  },
  watch: {
    selectedMic(newValue, oldValue) {
      if (oldValue == null || newValue === oldValue) {
        return;
      }
      console.log('selectedMicChanged')
      console.log(newValue)
      console.log(oldValue)

      let self = this;

      self.getMedia({
        audio: {
          deviceId: {
            exact: newValue
          }
        }
      }).then((newStream) => {
        let localStream = self.localStream;

        self.signalClient.peers().forEach(function (peer) {
          let oldTrack = localStream.getAudioTracks()[0];
          let newTrack = newStream.getAudioTracks()[0];
          peer.replaceTrack(oldTrack, newTrack, localStream);

          localStream.removeTrack(oldTrack)
          localStream.addTrack(newTrack)
        });
      });
    },
    selectedCamera(newValue, oldValue) {
      if (oldValue == null || newValue === oldValue) {
        return;
      }
      let self = this;
      console.log('selectedCameraChanged')
      console.log(newValue)
      console.log(oldValue)

      self.getMedia({
        video: {
          deviceId: {
            exact: newValue
          }
        }
      }).then((newStream) => {
        let localStream = self.localStream;

        self.signalClient.peers().forEach(function (peer) {
          let oldTrack = localStream.getVideoTracks()[0];
          let newTrack = newStream.getVideoTracks()[0];
          peer.replaceTrack(oldTrack, newTrack, localStream);

          localStream.removeTrack(oldTrack)
          localStream.addTrack(newTrack)
        });
      });
    }
  },
  computed: {
    cameraIcon() {
      if (this.enableVideo) {
        return "mdi-camera"
      } else {
        return "mdi-camera-off"
      }
    },
    micIcon() {
      if (this.enableAudio) {
        return "mdi-microphone"
      } else {
        return "mdi-microphone-off"
      }
    },
  },
  props: {
    roomId: {
      type: String,
      default: 'public-room-v2'
    },
    socketURL: {
      type: String,
      default: 'https://asistvet-signaling.herokuapp.com/'
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    peerOptions: {
      type: Object,  // NOTE: use these options: https://github.com/feross/simple-peer
      default() {
        return {
          config: {
            iceServers: [
              {urls: 'stun:stun.l.google.com:19302'},
              {urls: 'stun:global.stun.twilio.com:3478?transport=udp'},
              {
                urls: 'turn:turn.asistvet.com:3479',
                username: 'test',
                credential: 'test123'
              }]
          },
        };
      }
    },
    deviceId: {
      type: String,
      default: null
    }
  },
  mounted() {
    this.join();

    this.getConnectedDevices('videoinput').then((result) => {
      console.log("camera")
      this.availableCameras = result;
      this.selectedCamera = this.availableCameras[0].deviceId;
      console.log(this.selectedCamera)
    }).catch(err => console.log(err));

    this.getConnectedDevices('audioinput').then((result) => {
      console.log("mic")
      console.log(result)
      this.availableMicrophones = result;
      this.selectedMic = this.availableMicrophones[0].deviceId;
      console.log(this.selectedMic);
    }).catch(err => console.log(err));

  },
  methods: {
    async getConnectedDevices(type) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter(device => device.kind === type)
    },
    toggleLocalVideo() {
      this.enableVideo = !this.enableVideo;
      this.localStream.getVideoTracks()[0].enabled = this.enableVideo;
    },
    toggleLocalAudio() {
      this.enableAudio = !this.enableAudio;
      this.localStream.getAudioTracks()[0].enabled = this.enableAudio;
      console.log(this.localStream)
    },
    async join() {
      const that = this;
      this.log('join');
      this.socket = io(this.socketURL, {rejectUnauthorized: false, transports: ['websocket']});
      this.signalClient = new SimpleSignalClient(this.socket);
      const constraints = {
        video: {deviceId: this.selectedCamera},
        audio: {
          deviceId: this.selectedMic,
          echoCancellation: true
        }
      };
      if (that.deviceId && that.enableVideo) {
        constraints.video = {deviceId: {exact: that.deviceId}};
      }
      this.localStream = await navigator.mediaDevices.getUserMedia(constraints);
      this.log('opened', this.localStream);
      this.joinedRoom(this.localStream, true);
      this.signalClient.once('discover', (discoveryData) => {
        that.log('discovered', discoveryData)

        async function connectToPeer(peerID) {
          if (peerID == that.socket.id) return;
          try {
            that.log('Connecting to peer');
            const {peer} = await that.signalClient.connect(peerID, that.roomId, that.peerOptions);
            that.videoList.forEach(v => {
              if (v.isLocal) {
                that.onPeer(peer, v.stream);
              }
            })
          } catch (e) {
            that.log('Error connecting to peer');
          }
        }

        discoveryData.peers.forEach((peerID) => connectToPeer(peerID));
        that.$emit('opened-room', that.roomId);
      });
      this.signalClient.on('request', async (request) => {
        that.log('requested', request)
        const {peer} = await request.accept({}, that.peerOptions)
        that.log('accepted', peer);
        that.videoList.forEach(v => {
          if (v.isLocal) {
            that.onPeer(peer, v.stream);
          }
        })
        this.peerConnections.push(peer);
      })
      this.signalClient.discover(that.roomId);
    },
    onPeer(peer, localStream) {
      var that = this;
      that.log('onPeer');
      peer.addStream(localStream);
      peer.on('stream', (remoteStream) => {
        that.joinedRoom(remoteStream, false);
        peer.on('close', () => {
          var newList = [];
          that.videoList.forEach(function (item) {
            if (item.id !== remoteStream.id) {
              newList.push(item);
            }
          });
          that.videoList = newList;
          that.$emit('left-room', remoteStream.id);
        });
        peer.on('error', (err) => {
          that.log('peer error ', err);
        });
      });
      console.log("peer")
      console.log(peer)
    },
    joinedRoom(stream, isLocal) {
      var that = this;
      let found = that.videoList.find(video => {
        return video.id === stream.id
      })
      if (found === undefined) {
        let video = {
          id: stream.id,
          muted: isLocal,
          stream: stream,
          isLocal: isLocal
        };

        that.videoList.push(video);
      }

      setTimeout(function () {
        for (var i = 0, len = that.$refs.videos.length; i < len; i++) {
          if (that.$refs.videos[i].id === stream.id) {
            that.$refs.videos[i].srcObject = stream;
            break;
          }
        }
      }, 500);

      that.$emit('joined-room', stream.id);
    },
    leave() {
      this.videoList.forEach(v => v.stream.getTracks().forEach(t => t.stop()));
      this.videoList = [];
      this.signalClient.peers().forEach(peer => peer.removeAllListeners())
      this.signalClient.destroy();
      this.signalClient = null;
      this.socket.destroy();
      this.socket = null;
    },
    getCanvas() {
      let video = this.$refs.videos[0];
      if (video !== null && !this.ctx) {
        let canvas = document.createElement('canvas');
        canvas.height = video.clientHeight;
        canvas.width = video.clientWidth;
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d');
      }
      const {ctx, canvas} = this;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      return canvas;
    },
    async getMedia(constraints) {
      let stream = null;
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        return stream;
      } catch (err) {
        console.log(err)
      }
    },
    log(message, data) {
      if (this.enableLogs) {
        console.log(message);
        if (data != null) {
          console.log(data);
        }
      }
    }
  }
};
</script>
<style scoped>
video {
  width: 100%;
  max-width: 500px;
  height: auto;
}

</style>
