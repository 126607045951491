<template>
  <v-layout justify-center align-center class="pa-10">
    <v-card elevation="2" max-width="600" min-width="300">
      <v-toolbar
          flat
          class="blue lighten-2">
        <v-icon color="white">mdi-account</v-icon>
        <v-toolbar-title class="white--text">
          Şifre Güncelle
        </v-toolbar-title>
      </v-toolbar>

      <v-form class="ma-4" @submit.prevent="resetPassword">

        <v-card-text>
          <p class="text-body-1 text-center">Lütfen kullanmak istediğiniz şifreyi giriniz.</p>

          <v-text-field label="Şifre" type="password"
                        id="password"
                        v-model.trim="password" required @focusout="clearAlerts"/>

          <v-text-field label="Şifre Tekrar" type="password"
                        id="secondPassword"
                        v-model.trim="secondPassword" required @focusout="clearAlerts"/>
        </v-card-text>

        <v-card-actions class="pa-3 align-center center">
          <v-expand-transition>
            <v-alert max-width="400px" min-width="100px" class="mx-auto" v-if="resultMessage" border="bottom"
                     :color="resultColor" dark
                     :type="resultType"
                     elevation="2">{{ resultMessage }}
            </v-alert>
          </v-expand-transition>
          <v-spacer></v-spacer>
          <v-btn type="submit" :disabled="isDisabled" color="primary">
            İLERİ
          </v-btn>
        </v-card-actions>
      </v-form>

    </v-card>

  </v-layout>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      password: null,
      secondPassword: null,
      passwordRules: [
        v => !!v || 'Lütfen kullanmak istediğiniz şifreyi giriniz.',
      ],
      resultMessage: '',
      resultType: '',
      resultColor: '',
      token: null,
      userId: null,
      userType: null
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    this.token = urlParams.get('token');
    this.userType = urlParams.get('userType');
    this.userId = urlParams.get('id');

  },
  computed: {
    isDisabled() {
      return this.password === null
          || this.password === ""
          || this.password.length < 6
          || this.secondPassword === null
          || this.secondPassword === ""
          || this.secondPassword.length < 6
          || this.password !== this.secondPassword;
    },
  },
  methods: {
    clearAlerts() {
      this.errorText = null;
    },
    async resetPassword() {
      console.log('resetPassword')

      const url = process.env.VUE_APP_BASE_URL + '/auth/resetPassword'

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'userType': this.userType
        },
        body: JSON.stringify({
          userId: this.userId,
          token: this.token,
          newPassword: this.password
        })
      });

      const responseData = response.data;

      if (response.status !== 200) {
        throw new Error(
            responseData.message || 'Bir hata oluştu.'
        );
      }

      this.resultMessage = responseData.result.message;
      this.resultType = 'success';

    }
  },
}
</script>

<style scoped>

</style>