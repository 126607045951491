import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import "./plugins/vuetify-mask.js";
import router from './router'
import store from './store'
import axios from 'axios';
import vueCookies from 'vue-cookies'
import "./vee-validate";

Vue.config.productionTip = false

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = vueCookies.get("token");
    if(token === null){
        router.replace('/pr/login')
    }
    config.headers.Authorization = "Bearer " + token;
    config.headers['Content-Type'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = "*";
    config.headers['userType'] = localStorage.getItem('usertype');
    return config;
});

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    console.log(error.response.data)
    if (error.response.status === 401) {
        vueCookies.remove("token")
        router.replace('/pr/login')
    }
    return Promise.reject(error)
})


new Vue({
    vuetify,
    router,
    store,
    axios,
    vueCookies,

    render: h => h(App)
}).$mount('#app')
