<template>
  <v-flex>
    <v-row>
      <v-col  class="d-flex" style="flex-direction:column">
        <conversation-card :user-id="userId" :appointment-id="appointmentId"></conversation-card>
      </v-col>
      <v-col  class="d-flex" style="flex-direction:column">
        <patient-info :patient="patient"></patient-info>
      </v-col>
    </v-row>
    <progress-overlay :is-loading="isLoading"></progress-overlay>
  </v-flex>
</template>

<script>
import ConversationCard from "@/pages/common/components/ConversationCard";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import PatientInfo from "@/pages/common/components/PatientInfo";
import axios from "axios";

export default {
  name: "Conversation",
  components: {PatientInfo, ProgressOverlay, ConversationCard},
  data() {
    return {
      isLoading:false,
      patient: {},
      userId:'',
      appointmentId:''
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    this.appointmentId = urlParams.get('appointmentId');

    this.getAppointmentDetails();
  },
  methods: {
    updateOverlay(isEnabled) {
      this.isLoading = isEnabled;
    },
    async getAppointmentDetails() {
      console.log('getAppointmentDetails')
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/appointment/getAppointmentDetail?appointmentId=' + this.appointmentId;
      this.userId = localStorage.getItem('userId')

      axios.get(url).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Vaka bilgilerine erişilemedi.'
          );
        }

        this.patient = responseData.appointmentDetailResponse.patient;
        this.updateOverlay(false);

      });

    }
  },
}
</script>

<style scoped>

</style>