<template>
  <v-card  color="#F2F2F2" rounded width="500" elevation="2">
    <v-card-text>
      <v-row class="justify-center align-center">
        <v-col cols="2">
          <v-row class="justify-center">
            <v-avatar color="light-green" size="62">
              <img v-if="specialist.profilePhoto"
                   :src="specialist.profilePhoto"
                   :alt="specialist.name">
              <v-icon v-else dark x-large>
                mdi-account-circle
              </v-icon>
            </v-avatar>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row class="ml-5 mt-2">
            <p class="text-h5 white--text text-no-wrap light-blue--text darken-1">{{ specialist.degree }} {{ specialist.name }}</p>
          </v-row>
          <v-row class="ml-5 mt-2">
            <p class="font-italic font-weight-bold white--text text-no-wrap light-blue--text darken-1">{{ specialist.speciality }}</p>
          </v-row>
          <v-row class="ml-5 mt-2">
            <p class="white--text light-blue--text darken-1">Özel İlgi Alanı: {{ specialist.specialInterests }}</p>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row class="justify-center">
            <p class="display-1 font-weight-bold  white--text light-blue--text darken-1">{{ specialist.price }} ₺</p>
          </v-row>
          <v-row v-if="showButton" class="justify-center">
            <v-btn class="primary">VAKA DANIŞ</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "SpecialistInfoCard",
  props: ['specialist', 'showButton'],
  created() {
    console.log(this.specialist);
  }
}
</script>

<style scoped>

</style>