<template>
  <v-dialog
      @click:outside="closeDialog"
      v-model="isVisible"
      max-width="600px">
    <v-card>

      <v-card-title>
        <span class="text-h5">GÖRÜŞMENİZ GERÇEKLEŞTİ Mİ?</span>
      </v-card-title>
      <v-form @submit.prevent="updateAppointmentStatus(true)">
        <v-card-text>
          <p class="body-1">
            Uzmanla görüşmeniz tamamlandıysa artık hasta takip aşamasına geçebilir ve tedavi sürecinde sorularınızı
            sorabilirsiniz.
          </p>
          <v-textarea
              label="Eklemek istediğiniz bir not varsa burada belirtebilirsiniz."
              required
              outlined
              v-model.trim="message"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="red white--text"
              text
              @click="updateAppointmentStatus(false)">GÖRÜŞME GERÇEKLEŞMEDİ
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="primary"
              text
              type="submit">
            GÖRÜŞME TAMAMLANDI
          </v-btn>

        </v-card-actions>
      </v-form>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UpdateAppointmentStatusDialog",
  props: ['isVisible'],
  data() {
    return {
      message: ""
    }
  },
  methods: {
    updateAppointmentStatus(isCompleted) {
      this.$emit("updateAppointmentStatusOnDialog", isCompleted, this.message);
    },
    closeDialog() {
      this.$emit('dismissed');
    }
  },
}
</script>

<style scoped>

</style>