<template>
<section>
  <v-card elevation="2">
    <v-tabs
        v-model="tab"
        background-color="light-blue accent-4"
        center-active
        dark>
      <v-tab>İŞLEMDEKİLER</v-tab>
      <v-tab>ONAY BEKLEYENLER</v-tab>
      <v-tab>GEÇMİŞ</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="scheduled">
        <specialist-scheduled-appointments :is-header-visible="false" @updateOverlay="updateOverlay"></specialist-scheduled-appointments>
      </v-tab-item>

      <v-tab-item key="awaiting">
        <specialist-awaiting-appointments :is-header-visible="false"
                                          @updateOverlay="updateOverlay"></specialist-awaiting-appointments>
      </v-tab-item>

      <v-tab-item key="past">
        <specialist-past-appointments-table :is-header-visible="false"></specialist-past-appointments-table>
      </v-tab-item>
    </v-tabs-items>

  </v-card>
  <progress-overlay :is-loading="isLoading"></progress-overlay>
</section>
</template>

<script>
import SpecialistScheduledAppointments from "@/pages/specialist/components/SpecialistScheduledAppointments";
import SpecialistAwaitingAppointments from "@/pages/specialist/components/SpecialistAwaitingAppointmentsTable";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import SpecialistPastAppointmentsTable from "@/pages/specialist/components/SpecialistPastAppointmentsTable";

export default {

  components: {
    SpecialistPastAppointmentsTable,
    ProgressOverlay, SpecialistAwaitingAppointments, SpecialistScheduledAppointments},

  name: "SpecialistAppointments",
  data() {
    return {
      tab: null,
      isLoading: false
    }
  },
  methods: {
    updateOverlay(isEnabled) {
      this.isLoading = isEnabled;
    }
  },
}
</script>

<style scoped>

</style>