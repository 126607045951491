<template>
  <section>
    <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="isVisible"
        @click:outside="closeDialog">
      <v-card>
        <v-toolbar
            color="primary"
            dark>
          Zaman Aralığı Seçiniz
        </v-toolbar>
        <v-card-text class="my-4">
          <v-row>
            <v-col cols="5">
              <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="startTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="startTime"
                      label="Başlangıç Zamanı"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu1"
                    :allowed-minutes='allowedStep'
                    v-model="startTime"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu1.save(startTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="5">
              <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="endTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="endTime"
                      label="Bitiş Zamanı"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="endTime"
                    full-width
                    format="24hr"
                    :min="startTime"
                    :allowed-minutes='allowedStep'
                    @click:minute="$refs.menu2.save(endTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-expand-transition>
            <v-alert width="400px" class="mx-auto" v-if="errorMessage" border="bottom"
                     color="red" dark
                     type="error"
                     elevation="2">{{ errorMessage }}
            </v-alert>
          </v-expand-transition>
          <v-spacer>
          </v-spacer>
          <v-btn class="primary justify-center align-center" @click="addSlotToDay">EKLE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  name: "TimeSlotPicker",
  props: ['dayCode', 'currentAvailabilities'],
  data() {
    return {
      startTime: null,
      endTime: null,
      menu1: false,
      menu2: false,
      errorMessage: null,
      attr: {},
      on: {}
    }
  },
  computed: {
    isVisible() {
      return this.dayCode
    }
  },
  methods: {
    allowedStep: m => m % 20 === 0,
    addSlotToDay() {
      //TODO add overlapping timeslot check
      console.log('addSlotToDay')

      if (this.startTime === null || this.endTime === null) {
        this.errorMessage = 'Lütfen başlangıç ve bitiş zamanı seçiniz.';
        window.setInterval(() => {
          this.errorMessage = null;
        }, 2000);
        return;
      }

      if (this.startTime > this.endTime) {
        this.errorMessage = 'Başlangıç zamanı bitiş zamanından geç olamaz.';

        window.setInterval(() => {
          this.errorMessage = null;
        }, 2000);
        return;
      }

      if (this.currentAvailabilities !== null) {
        const startCheck = this.currentAvailabilities.find(a => a.start === this.startTime);
        if (startCheck) {
          this.errorMessage = 'Bu başlangıç zamanı için kayıtlı bir aralık mevcut.';

          window.setInterval(() => {
            this.errorMessage = null;
          }, 2000);
          return;
        }

        const endCheck = this.currentAvailabilities.find(a => a.end === this.endTime);
        if (endCheck) {
          this.errorMessage = 'Bu bitiş zamanı için kayıtlı bir aralık mevcut.';

          window.setInterval(() => {
            this.errorMessage = null;
          }, 2000);
          return;
        }
      }

      const payload = {
        start: this.startTime,
        end: this.endTime,
        dayCode: this.dayCode
      }
      this.$emit("addSlotToDay", payload);
    },
    closeDialog() {
      this.$emit("addSlotToDay", null);
    }
  },
}
</script>

<style scoped>

</style>