<template>
  <section>
    <specialist-info-card v-for="specialist in specialists" :key="specialist.id"
                          :specialist="specialist"></specialist-info-card>
    <progress-overlay :is-loading="isLoading"></progress-overlay>
  </section>
</template>

<script>
import SpecialistInfoCard from "@/pages/practitioner/components/SpecialistInfoCard";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import axios from "axios";

export default {
  name: "SpecialistList",
  components: {ProgressOverlay, SpecialistInfoCard},
  data() {
    return {
      specialists: [],
      isLoading:false
    }
  },
  created() {
    this.getAllSpecialists();
  },
  methods: {
    async getAllSpecialists() {
      console.log("getAllSpecialists")
      this.isLoading = true;

      const url = process.env.VUE_APP_BASE_URL + '/practitioner/appointment/getAllActiveSpecialists'

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Uzmanların bilgilerine erişilemedi.'
          );
        }

        this.specialists = responseData.specialists;
        this.isLoading=false;
      }).catch( err =>
          console.log(err)
      )

    }
  },
}
</script>

<style scoped>

</style>