<template>
  <v-card elevation="2" max-width="600" min-width="200px">
    <v-toolbar
        flat
        class="blue lighten-2">
      <v-icon color="white">mdi-account</v-icon>
      <v-toolbar-title class="white--text">
        Uzmanlık Bilgileri
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
          color="white"
          fab
          small
          @click="toggleSpecialistForm">
        <v-icon v-if="formEnabled">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-form :disabled="!formEnabled" class="align-center mx-auto pa-3" @submit.prevent="saveSpecialistInfo">
      <v-card-text>
        <v-select
            label="Ünvan"
            item-text="name"
            item-value="id"
            v-model="diplomaDegree"
            :items="diplomaDegrees"
            :rules="notEmpty"
            aria-required="true"
        ></v-select>
        <v-select
            v-model="fieldId"
            :items="fields"
            item-text="name"
            item-value="id"
            label="Uzmanlık Alanı"
            :rules="notEmpty"
            required
        ></v-select>
        <v-file-input
            :rules="fileUploadRules"
            accept="image/*,.pdf"
            counter
            show-size
            truncate-length="40"
            chips
            label="Alanınızdaki derecenizi kanıtlayacak belgeyi ekleyiniz"
            hint="Ör. Profesörlük Belgesi/ Doktora Diploması"
            @change="uploadSpecialityDiploma"
            v-model="file"
        ></v-file-input>
        <v-autocomplete
            id="university"
            v-model="university"
            :items="universities"
            item-text="name"
            item-value="code"
            :rules="notEmpty"
            label="Derecenin Alındığı Üniversite"
            required
        ></v-autocomplete>
        <date-picker label="Derecenin Alındığı Sene" @dateSelected="setGraduationDate"
                     :rules="notEmpty"
                     :selectedDate="graduationDate"></date-picker>
        <v-text-field label="Özel İlgi Alanları"
                      type="text"
                      id="specialInterests"
                      :rules="notEmpty"
                      v-model.trim="specialInterests"/>
        <v-textarea label="Kısa özgeçmiş"
                    rows="4"
                    name="bio"
                    counter="500"
                    hint="Kısaca profesyonel tecrübelerinizden bahsedebilirsiniz."
                    no-resize
                    :rules="notEmpty"
                    v-model="bio"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-expand-transition>
          <v-alert width="400px" class="mx-auto" v-if="resultMessage" border="bottom"
                   :color="resultColor" dark
                   :type="resultType"
                   elevation="2">{{ resultMessage }}
          </v-alert>
        </v-expand-transition>
        <v-spacer></v-spacer>
        <v-btn class="light-green white--text" type="submit" :disabled='!saveBtnEnabled'>
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import DatePicker from "@/pages/practitioner/components/common/DatePicker";
import axios from "axios";

export default {
  name: "SpecialistInformation",
  components: {DatePicker},
  data() {
    return {
      fields: [{id: 'OBSTETRICS', name: 'Doğum ve Jinekoloji'}, {id: 'SURGERY', name: 'Cerrahi'}, {
        id: 'INTERNAL',
        name: 'İç hastalıkları'
      }],
      diplomaDegrees: [{id: 'PROF', name: 'Profesör'}, {id: 'DR', name: 'Doktor'}, {
        id: 'ASSOCIATE_PROF',
        name: 'Doçent Doktor'
      },],
      fieldId: '',
      formEnabled: false,
      diplomaDegree: '',
      specialInterests: '',
      fileUploadRules: [
        value => !value || value.size < 25000000 || 'Dosya boyutu 25 MB\'dan fazla olamaz.',
      ],
      file: null,
      specialityDiplomaUrl: '',
      university: '',
      graduationDate: '',
      universities: [],
      bio: '',
      resultType: '',
      resultColor: '',
      resultMessage: '',
      notEmpty: [
        v => !!v || 'Lütfen bu alanı doldurunuz.',
      ],
    }
  },
  created() {
    this.getUniversities();
    this.getSpecialityInformation();
  },
  computed: {
    saveBtnEnabled() {
      return this.formEnabled
          && this.university
          && this.bio
          && this.graduationDate
          && this.specialInterests
          && this.fieldId != null
          && this.diplomaDegree != null
    }
  },
  methods: {
    async getSpecialityInformation() {
      console.log('getSpecialityInformation');
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/getSpecialityInformation'

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Uzman bilgilerine erisilemedi.'
          );
        }

        this.diplomaDegree = responseData.diplomaDegree;
        this.fieldId = responseData.speciality;
        this.specialityDiplomaUrl = responseData.diplomaFileLink;
        this.university = responseData.universityId;
        this.graduationDate = responseData.graduationDate;
        this.specialInterests = responseData.specialInterests;
        this.bio = responseData.bio;

        this.updateOverlay(false);
      })

    },
    updateOverlay(isEnabled) {
      this.$emit("updateOverlay", isEnabled);
    },
    async uploadSpecialityDiploma() {
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/uploadDiploma'

      const data = new FormData()
      data.append('file', this.file)

      axios.post(url, data).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Diploma yuklenirken bir hata oluştu.'
          );
        }

        if (responseData.result.success) {
          this.resultType = 'success';
          this.resultColor = 'light-blue'
        } else {
          this.resultType = 'error';
          this.resultColor = 'pink darken-1'
        }
        this.resultMessage = responseData.result.message || 'Diploma yüklenirken bir hata oluştu.';

        this.specialityDiplomaUrl = process.env.VUE_APP_BASE_URL + '/files/' + responseData.fileId;

        this.updateOverlay(false);

        window.setInterval(() => {
          this.resultMessage = null;
        }, 3000)
      })

    },
    async getUniversities() {
      const url = process.env.VUE_APP_BASE_URL + '/general/getUniversities'

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Üniversite bilgileri çekilirken hata olustu.'
          );
        }

        this.universities = responseData;
      });

    },
    setGraduationDate(date) {
      this.graduationDate = date;
    },
    async saveSpecialistInfo() {
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/updateSpecialityInformation'

      const data = {
        diplomaDegree: this.diplomaDegree,
        speciality: this.fieldId,
        diplomaFileLink: this.specialityDiplomaUrl,
        universityId: this.university,
        graduationDate: this.graduationDate,
        specialInterests: this.specialInterests,
        bio: this.bio
      }

      axios.post(url, data).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Uzman bilgileri güncellenemedi.'
          );
        }

        if (responseData.result.success) {
          this.resultType = 'success';
          this.resultColor = 'light-blue'
          this.formEnabled = false;
        } else {
          this.resultType = 'error';
          this.resultColor = 'pink darken-1'
        }
        this.resultMessage = responseData.result.message || 'Bilgileriniz güncellenirken bir hata oluştu.';

        this.updateOverlay(false);

        window.setInterval(() => {
          this.resultMessage = null;
        }, 3000)
      })

    },
    toggleSpecialistForm() {
      this.formEnabled = !this.formEnabled;
    }
  },
}
</script>

<style scoped>

</style>