<template>
  <v-btn icon color="primary"
         dark
         @click="gotoAppointments()">
    <v-icon>mdi-email</v-icon>
  </v-btn>
</template>

<script>
import router from "@/router";

export default {
  name: "InboxButton",
  methods: {
    gotoAppointments() {
      router.replace('appointments');
    }
  },
}
</script>

<style scoped>

</style>