<template>
  <div>
    <v-card width="600" elevation="2">
      <v-tabs
          v-model="tab"
          background-color="light-blue accent-4"
          center-active
          dark>
        <v-tab>ÖDEME BİLGİLERİM</v-tab>
        <v-tab>KAZANÇLARIM</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="paymentInfo">
          <payment-info></payment-info>
        </v-tab-item>
        <v-tab-item key="paymentHistory">
          <textarea>Test</textarea>
        </v-tab-item>
<!--        <v-tab-item
            v-for="item in items"
            :key="item.tab">
          <v-card flat>
            <v-card-text>{{ item.content }}</v-card-text>
          </v-card>
        </v-tab-item>-->
      </v-tabs-items>

    </v-card>
  </div>
</template>

<script>
import PaymentInfo from "@/pages/specialist/components/PaymentInfo";
export default {
  name: "specialistPaymentInfo",
  components: {PaymentInfo},
  data () {
    return {
      tab: null,
    }
  },
}
</script>

<style scoped>

</style>