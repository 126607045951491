<template>
  <v-flex>
    <events-calendar @updateOverlay="updateOverlay"></events-calendar>
    <practitioner-awaiting-appointments @updateOverlay="updateOverlay"></practitioner-awaiting-appointments>
    <practitioner-scheduled-appointments @updateOverlay="updateOverlay"></practitioner-scheduled-appointments>
    <progress-overlay :is-loading="isLoading"></progress-overlay>
  </v-flex>
</template>

<script>
import EventsCalendar from "@/pages/practitioner/components/dashboard/EventsCalendar";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import PractitionerScheduledAppointments
  from "@/pages/practitioner/components/appointment/PractitionerScheduledAppointments";
import PractitionerAwaitingAppointments
  from "@/pages/practitioner/components/appointment/PractitionerAwaitingAppointments";

export default {
  name: "practitionerDashboard",
  components: {
    PractitionerAwaitingAppointments,
    PractitionerScheduledAppointments,
    ProgressOverlay, EventsCalendar},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    updateOverlay(isEnabled) {
      this.isLoading = isEnabled;
    }
  }
}
</script>

<style scoped>

</style>