<template>
  <section>
    <v-alert width="400px" class="mx-auto" v-if="errorText" border="bottom" color="pink darken-1" dark type="error"
             elevation="2">{{ errorText }}
    </v-alert>
    <v-card elevation="2" outlined width="300px" class="mx-auto">
      <v-form class="align-center" @submit.prevent="submitSignup">
        <v-card-title>Klinisyen Kayıt</v-card-title>
        <v-card-text>
          <!--        <v-file-input label="Fotoğraf Yükle" v-model="image" prepend-icon="mdi-camera"/>-->
          <v-text-field label="İsim" type="text" id="name" v-model.trim="firstName" @focusout="clearAlerts"/>
          <v-text-field label="Soyisim" type="text" id="lastName" v-model.trim="lastName" @focusout="clearAlerts"/>
          <v-text-field label="Doğum Tarihi" type="date" id="dob" v-model.trim="dob" @focusout="clearAlerts"/>
          <v-text-field label="Telefon Numarası" type="tel" id="phone" v-model.trim="phone" @focusout="clearAlerts"/>
        </v-card-text>
        <v-card-actions>
          <v-btn to="/pr/signup">Geri</v-btn>

          <v-btn type="submit">
            Kaydı Tamamla
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "Signup",
  data() {
    return {
      firstName: '',
      lastName: '',
      dob: null,
      phone: '',
      errorText: null
    }
  },
  methods: {
    submitSignup() {
      if (this.firstName.length === 0
          || this.lastName.length === 0
          || this.dob === null
          || this.phone.length === 0) {
        this.errorText = 'Lütfen tüm alanları doldurunuz.'
      }

      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        birthDate: this.dob
      }

      this.$store.dispatch('auth/prSendSignupRequest', payload)
    },
    clearAlerts() {
      this.errorText = null;
    }

  }
}
</script>

<style scoped>

</style>