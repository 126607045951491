export default {
    prSignupForm(state) {
        return state.prSignupForm;
    },
    calendarNeedsAction(state) {
        return state.calendarNeedsAction;
    },
    profileNeedsAction(state) {
        return state.profileNeedsAction;
    },
    settingsNeedsAction(state) {
        return state.settingsNeedsAction;
    },
    paymentsNeedsAction(state) {
        return state.paymentsNeedsAction;
    },

};