<template>
  <section>
    <v-row>
      <v-col>
        <specialist-information @updateOverlay="updateOverlay"></specialist-information>
      </v-col>
      <v-col>
        <account-information @updateOverlay="updateOverlay"></account-information>
      </v-col>
    </v-row>
    <progress-overlay :is-loading="isLoading"></progress-overlay>
  </section>
</template>

<script>
import AccountInformation from "@/pages/common/components/AccountInformation";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import SpecialistInformation from "@/pages/specialist/components/SpecialistInformation";

export default {
  name: "specialistAccount",
  components: {SpecialistInformation, ProgressOverlay, AccountInformation},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    updateOverlay(isEnabled) {
      this.isLoading = isEnabled;
    }
  }
}
</script>

<style scoped>

</style>