import {extend, localize} from "vee-validate";
import {min, required, email, confirmed,alpha,digits} from "vee-validate/dist/rules";
import tr from "vee-validate/dist/locale/tr.json";

// Install rules
extend("required", required);
extend("min", min);
extend("email", email);
extend("confirmed", confirmed);
extend("alpha", alpha);
extend("digits", digits);

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Girilen şifreler aynı değil'
});

extend('beforeNow', {
    validate(value) {
        let date = new Date(value)
        let today = new Date();
        return today > date;
    },
    message: 'Doğum tarihi gelecekte olamaz'
});

// Install messages
localize('tr', {
    "code": "tr",
    "messages": tr.messages,
    "names": {
        "email": "E-posta",
        "password":"Şifre",
    },
    "fields": {
        "password": {
            "min": "{_field_} çok kısa"
        }
    }
})

