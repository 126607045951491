<template>
  <section>
    <v-row>
      <v-col>
        <account-information @updateOverlay="updateOverlay"></account-information>
      </v-col>
      <v-col>
        <clinic-information @updateOverlay="updateOverlay"></clinic-information>
      </v-col>
    </v-row>
    <progress-overlay :is-loading="isLoading"></progress-overlay>
  </section>
</template>

<script>
import ClinicInformation from "@/pages/practitioner/components/account/ClinicInformation";
import AccountInformation from "@/pages/common/components/AccountInformation";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";

export default {
  name: "practitionerAccount",
  components: {ProgressOverlay, AccountInformation, ClinicInformation},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    updateOverlay(isEnabled) {
      this.isLoading = isEnabled;
    }
  }
}
</script>

<style scoped>

</style>