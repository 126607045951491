<template>
<login :is-specialist="false"></login>
</template>

<script>
import Login from "@/pages/common/components/Login";


export default {
  name: "PractitionerLogin",
  components: {Login},

}
</script>

<style scoped>

</style>