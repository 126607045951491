<template>
  <section>
    <p class="text-h4 ma-2">Ajanda</p>
    <v-row class="fill-height">
      <v-col class="elevation-2 pa-0 ma-3">
        <v-sheet height="64">
          <v-toolbar
              flat
          >
            <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
            >
              Bugün
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
                bottom
                right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Gün</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Hafta</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Ay</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 Gün</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
              weekdays="1, 2, 3, 4, 5, 6, 0"
              locale="tr"
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
          ></v-calendar>
          <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
          >
            <v-card
                color="grey lighten-4"
                min-width="350px"
                flat
            >
              <v-toolbar
                  :color="selectedEvent.color"
                  dark
              >
                <v-toolbar-title v-html="selectedEvent.details.title"></v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <p>Tür: {{selectedEvent.details.species}}</p>
                <p>İsim: {{selectedEvent.details.name}}</p>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    text
                    color="secondary"
                    @click="selectedOpen = false">
                  DETAY
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "EventsCalendar",
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Ay',
      week: 'Hafta',
      day: 'Gün',
      '4day': '4 Gün',
    },
    selectedEvent: {
      details: {}
    },
    selectedElement: null,
    selectedOpen: false,
    events: [],
    calenderAppointments: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
  }),
  mounted() {
    this.$refs.calendar.checkChange()
  },
  methods: {
    updateOverlay(isEnabled) {
      this.$emit("updateOverlay", isEnabled);
    },
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    async updateRange({start, end}) {
      console.log('updateRange')
      this.updateOverlay(true);

      const events = []
      await this.findAppointments(start, end);

      this.calenderAppointments.forEach((ev) => {
        const startTime = new Date(ev.time);
        let endTime = new Date(ev.time);
        endTime = endTime.setMinutes(endTime.getMinutes() + 20);
        events.push({
          name: ev.name,
          start: startTime,
          details: ev,
          end: endTime,
          color: this.appointmentsColor(ev.status),
          timed: true,
        })
      })

      this.events = events
      console.log(events)
      this.updateOverlay(false);
    },
    appointmentsColor(status) {
      if (status === 'CREATED') {
        return 'cyan'
      } else if (status === 'APPROVED' || status === 'FOLLOW_UP') {
        return 'green'
      } else {
        return 'orange'
      }
    },
    async findAppointments(start, end) {
      console.log('findAppointments')
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/appointment/findAppointments'
      const viewStart = this.$refs.calendar.getStartOfWeek(start);
      const viewEnd = this.$refs.calendar.getEndOfWeek(end);

      const data = {
        searchType: 'TIME',
        startTime: viewStart.date + 'T00:00:00',
        endTime: viewEnd.date + 'T23:59:00'
      }
      let response = await axios.post(url, data);
      const responseData = response.data;

      if (response.status !== 200) {
        throw new Error(
            responseData.message || 'Vakaların bilgilerine erişilemedi.'
        );
      }
      this.calenderAppointments = responseData.appointments;
      this.updateOverlay(false);
    }
  }
}
</script>

<style scoped>

</style>