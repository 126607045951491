<template>
  <v-flex>
    <h2 class="ma-2" v-if="isHeaderVisible">Onay Bekleyen Vakalarım</h2>
    <v-data-table
        :headers="headers"
        :items="tableAppointments"
        no-data-text="Gösterilecek bir randevu bulunmamaktadır."
        :items-per-page="5"
        class="elevation-2">
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.patientName }}</td>
          <td>{{ row.item.species }}</td>
          <td>{{ row.item.specialistName }}</td>
          <td>{{ row.item.title }}</td>
          <td>{{ row.item.time }}</td>
          <td>{{ row.item.hour }}</td>

          <td>
            <v-btn class="ma-auto" @click="openDetail(row.item)">
              TALEBİ İNCELE
            </v-btn>
          </td>

        </tr>
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import axios from "axios";

export default {
  name: "PractitionerPastAppointments",
  props: {
    isHeaderVisible: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      tableAppointments: [],
      headers: [
        {
          text: 'Hasta Adı',
          align: 'start',
          sortable: false,
          value: 'patientName',
        },
        {text: 'Hasta Türü', value: 'species'},
        {text: 'Danışılacak Uzman', value: 'specialistName'},
        {text: 'Vaka Başlığı', value: 'title'},
        {text: 'Görüşme Tarihi', value: 'time'},
        {text: 'Görüşme Saati', value: 'hour'},
        {text: 'Durum', value: 'state'},
      ]
    }
  },
  created() {
    this.getPastAppointments();
  },
  methods: {
    openDetail(appointment) {
      this.$router.push('appointmentDetail?appointmentId=' + appointment.id);
    },
    updateOverlay(isEnabled) {
      this.$emit("updateOverlay", isEnabled);
    },
    async getPastAppointments() {
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/appointment/findAppointments'

      const data = {
        searchType: 'STATUS',
        appointmentStatus: ['CLOSED', 'DECLINED']
      };

      axios.post(url, data).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Vakaların bilgilerine erişilemedi.'
          );
        }

        const appointments = responseData.appointments;

        const appointmentArray = [];

        appointments.forEach((appointment) => {
          const dateTime = appointment.time.split('T');
          appointmentArray.push({
            id: appointment.id,
            patientName: appointment.name,
            species: appointment.species,
            specialistName: appointment.specialistName,
            title: appointment.title,
            status: appointment.status,
            time: dateTime[0],
            hour: dateTime[1],
            actionType: appointment.actionType
          })
        });

        this.tableAppointments = appointmentArray;
        this.updateOverlay(false);

      });

    }
  },
}
</script>

<style scoped>

</style>