<template>
<section>
  <v-card elevation="2">
    <v-tabs
        v-model="tab"
        background-color="light-blue accent-4"
        center-active
        dark>
      <v-tab>İŞLEMDEKİLER</v-tab>
      <v-tab>ONAY BEKLEYENLER</v-tab>
      <v-tab>GEÇMİŞ</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="scheduled">
        <practitioner-scheduled-appointments :is-header-visible="false"
                                             @updateOverlay="updateOverlay"></practitioner-scheduled-appointments>
      </v-tab-item>

      <v-tab-item key="awaiting">
        <practitioner-awaiting-appointments :is-header-visible="false"
                                            @updateOverlay="updateOverlay"></practitioner-awaiting-appointments>

      </v-tab-item>

      <v-tab-item key="past">
        <practitioner-past-appointments  :is-header-visible="false"></practitioner-past-appointments>
      </v-tab-item>
    </v-tabs-items>

  </v-card>
  <progress-overlay :is-loading="isLoading"></progress-overlay>
</section>
</template>

<script>
import PractitionerScheduledAppointments
  from "@/pages/practitioner/components/appointment/PractitionerScheduledAppointments";
import PractitionerAwaitingAppointments
  from "@/pages/practitioner/components/appointment/PractitionerAwaitingAppointments";
import PractitionerPastAppointments from "@/pages/practitioner/components/appointment/PractitionerPastAppointments";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";

export default {
  name: "PractitionerAppointments",
  components: {
    ProgressOverlay,
    PractitionerPastAppointments, PractitionerAwaitingAppointments, PractitionerScheduledAppointments
  },
  data() {
    return {
      isLoading: false,
      tab: null,
    }
  },
  methods: {
    updateOverlay(isEnabled) {
      this.isLoading = isEnabled;
    }
  }
}
</script>

<style scoped>

</style>