import { render, staticRenderFns } from "./PractitionerDashboard.vue?vue&type=template&id=2dc7803c&scoped=true&"
import script from "./PractitionerDashboard.vue?vue&type=script&lang=js&"
export * from "./PractitionerDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc7803c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex})
