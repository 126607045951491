<template>
  <v-flex>
    <events-calendar @updateOverlay="updateOverlay"></events-calendar>
    <specialist-awaiting-appointments @updateOverlay="updateOverlay"></specialist-awaiting-appointments>
    <specialist-scheduled-appointments @updateOverlay="updateOverlay"></specialist-scheduled-appointments>
    <progress-overlay :is-loading="isLoading"></progress-overlay>
  </v-flex>
</template>

<script>
import EventsCalendar from "@/pages/practitioner/components/dashboard/EventsCalendar";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import SpecialistScheduledAppointments from "@/pages/specialist/components/SpecialistScheduledAppointments";
import SpecialistAwaitingAppointments from "@/pages/specialist/components/SpecialistAwaitingAppointmentsTable";

export default {
  name: "specialistDashboard",
  components: {
    SpecialistAwaitingAppointments,
    SpecialistScheduledAppointments, ProgressOverlay, EventsCalendar},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    updateOverlay(isEnabled) {
      this.isLoading = isEnabled;
    }
  }
}
</script>

<style scoped>

</style>