<template>
  <v-card elevation="2" max-width="600" min-width="200px">
    <v-toolbar
        flat
        class="blue lighten-2">
      <v-icon color="white">mdi-account</v-icon>
      <v-toolbar-title class="white--text">
        Kişisel Bilgiler
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
          color="white"
          fab
          small
          @click="togglePersonalForm">
        <v-icon v-if="formEnabled">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-form :disabled='!formEnabled' class="align-center mx-auto pa-3" @submit.prevent="savePersonalInfo">
      <v-card-text>
        <v-row>
          <v-col>
            <v-avatar class="blue lighten-2" size="62">
              <img v-if="profilePicture"
                   :src="profilePicture"
                   alt="profilePicture">
              <v-icon v-else size="54" class="white--text">mdi-account-circle</v-icon>
            </v-avatar>
          </v-col>
          <v-col>
            <v-file-input
                show-size
                label="Profil Fotoğrafı Yükle"
                accept="image/*"
                @change="uploadProfilePicture"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-text-field label="E-Posta" type="email" id="email"
                      :rules="emailRules" v-model.trim="email"
                      required disabled/>

        <v-text-field label="İsim" type="text" id="name" v-model.trim="firstName" :rules="notEmpty"/>

        <v-text-field label="Soyisim" type="text" id="lastName" v-model.trim="lastName" :rules="notEmpty"/>

        <v-text-field label="Doğum Tarihi" type="date" id="dob" v-model.trim="dob" :rules="notEmpty"/>

        <v-text-field label="Telefon Numarası" type="tel" id="phone" v-model.trim="phoneNumber"
                      :rules="phoneRules"
                      hint="Telefon numaranızı başında sıfır olmadan 5XXXXXXXXX şeklinde giriniz."
                      counter
                      :prefix="'+90'"
                      maxlength="10"
        />
        <v-autocomplete
            id="university"
            v-model="diploma.school"
            :items="universities"
            :rules="notEmpty"
            item-text="name"
            item-value="name"
            label="Derecenin Alındığı Üniversite"
            required
        />
        <v-text-field label="Mezuniyet Tarihi"
                      type="date"
                      id="diplomaDate"
                      :rules="notEmpty"
                      v-model.trim="diploma.date"
        />
      </v-card-text>
      <v-card-actions>
        <v-expand-transition>
          <v-alert width="400px" class="mx-auto" v-if="resultMessage" border="bottom"
                   :color="resultColor" dark
                   :type="resultType"
                   elevation="2">{{ resultMessage }}
          </v-alert>
        </v-expand-transition>
        <v-spacer></v-spacer>
        <v-btn class="light-green white--text" type="submit" :disabled='!saveBtnEnabled'>
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import vueCookies from 'vue-cookies'

export default {
  name: "AccountInformation",
  data() {
    return {
      formEnabled: false,
      email: '',
      password: '',
      secondPassword: '',
      resultMessage: null,
      resultType: false,
      resultColor: 'light-blue',
      firstName: '',
      lastName: '',
      dob: null,
      phoneNumber: '',
      diploma: {
        date: null,
        school: ''
      },
      universities: [],
      profilePicture: null,
      emailRules: [
        v => !!v || 'Lütfen e-posta adresinizi giriniz.',
        v => /.+@.+/.test(v) || 'Lütfen geçerli bir e-posta adresi giriniz.',
      ],
      phoneRules: [
        v => !!v || 'Lütfen telefon numarası giriniz.',
        v => /^(5(\d{9}))/.test(v) || 'Lütfen geçerli bir telefon numarası giriniz.'
      ],
      nameRules: [
        v => !!v || 'Lütfen geçerli bir isim giriniz.',
        v => /^(5(\d{9}))/.test(v) || 'Lütfen geçerli bir telefon numarası giriniz.'
      ],
      notEmpty: [
        v => !!v || 'Lütfen bu alanı doldurunuz.',
      ],
    }
  },
  created() {
    this.getPersonalInfo();
    this.getUniversities();
  },
  computed: {
    saveBtnEnabled() {
      return this.formEnabled
          && this.email
          && this.firstName
          && this.lastName
          && this.dob
          && this.phoneNumber
          && this.diploma
          && this.diploma.date
          && this.diploma.school != null
    }
  },
  methods: {
    updateOverlay(isEnabled) {
      this.$emit("updateOverlay", isEnabled);
    },
    clearAlerts() {
      this.errorText = null;
    },
    togglePersonalForm() {
      this.formEnabled = !this.formEnabled;
    },
    async getPersonalInfo() {
      console.log('getPersonalInfo');
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/getAccountInformation'

      axios.get(url).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Kullanici bilgilerine erisilemedi.'
          );
        }

        this.firstName = responseData.firstName;
        this.lastName = responseData.lastName;
        this.dob = responseData.birthDate;
        this.phoneNumber = responseData.phoneNumber;
        this.diploma.school = responseData.university;
        this.diploma.date = responseData.graduationDate;
        this.email = responseData.email;
        this.profilePicture = responseData.profilePhoto;

        this.updateOverlay(false);

      });

    },
    async savePersonalInfo() {
      console.log('savePersonalInfo');
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/updateAccountInformation'

      const data = {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        birthDate: this.dob,
        phoneNumber: this.phoneNumber,
        university: this.diploma.school,
        graduationDate: this.diploma.date,
        profilePhoto: this.profilePicture
      };

      axios.post(url, data).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Kişisel bilgiler güncellenirken bir hata oluştu.'
          );
        }

        if (responseData.result.success) {
          this.resultType = 'success';
          this.formEnabled = false;
          this.resultColor = 'light-blue'
        } else {
          this.resultType = 'error';
          this.resultColor = 'pink darken-1'
        }
        this.resultMessage = responseData.result.message || 'Bilgileriniz güncellenirken bir hata oluştu.';

        this.updateOverlay(false);

      });

    },
    async getUniversities() {
      const url = process.env.VUE_APP_BASE_URL + '/general/getUniversities'

      axios.get(url).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Okul bilgileri cekilirken hata olustu.'
          );
        }

        this.universities = responseData;
      });
    },
    async uploadProfilePicture(file) {
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/uploadProfilePicture'
      const token = vueCookies.get("token");

      const headersList = new Headers();
      headersList.append("Authorization", "Bearer " + token);


      const data = new FormData()
      data.append('file', file)

      const requestOptions = {
        method: 'POST',
        headers: headersList,
        body: data,
      };

      const response = await fetch(url, requestOptions);

      const responseData = response.data;

      if (response.status !== 200) {
        throw new Error(
            responseData.message || 'Profil fotografi yuklenirken bir hata oluştu.'
        );
      }

      this.profilePicture = process.env.VUE_APP_BASE_URL + '/files/' + responseData.fileId;

      console.log(this.profilePicture)
      this.updateOverlay(false);

    },
  }
}
</script>

<style scoped>

</style>