<template>
  <v-dialog
      persistent
      max-width="400"
      v-model="isVisible">
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text class="text-sm-body-1">{{ result.message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="okayClicked">
          Tamam
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ResultDialog",
  props: ['isVisible', 'result'],
  computed: {
    title() {
      if (this.result.success === true) {
        return 'İşlem Başarılı'
      } else {
        return 'Bir Hata Oluştu'
      }
    }
  },
  methods: {
    okayClicked() {
      this.$emit("okayClicked");
    }
  },
}
</script>

<style scoped>

</style>