import Vue from 'vue'
import Vuex from 'vuex'

import authentication from "@/store/authentication";
import user from "@/store/user";
import appointment from "@/store/appointment";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth : authentication,
    user : user,
    appointment : appointment
  }
});

export default store;
