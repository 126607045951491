<template>
  <section>
    <v-menu offset-y class="mr-4">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
               :color="notificationIconColor"
               dark
               v-bind="attrs"
               v-on="on">
          <v-icon>{{ notificationIcon }}</v-icon>
        </v-btn>
      </template>

      <v-list class="mx-auto"
              max-width="400"
              min-width="200"
              three-line>
        <v-subheader>Bildirimler</v-subheader>
        <section v-if="notifications.length > 0">
          <v-list-item
              v-for="notification in notifications"
              :key="notification.id"
              link
              @click="clickOnNotification(notification)">
            <v-list-item-icon>
              <v-icon :color="getNotificationColor(notification.isSeen)">{{ getIcon(notification.type) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ notification.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ notification.message }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </section>
        <section v-else>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="grey">mdi-bell-off</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="grey--text">Yeni bir bildirim bulunmamakta</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </section>

      </v-list>

    </v-menu>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "NotificationButton",
  data() {
    return {
      notifications: [],
      unreadNotifications: false
    }
  },
  computed: {
    notificationIcon() {

      this.notifications.forEach(notification => {
        if (notification.isSeen === false) {
          this.unreadNotifications = true;
        }
      })

      if (this.unreadNotifications) {
        return 'mdi-bell-badge'
      } else {
        return 'mdi-bell'
      }
    },
    notificationIconColor() {
      this.notifications.forEach(notification => {
        if (notification.isSeen === false) {
          this.unreadNotifications = true;
        }
      })

      if (this.unreadNotifications) {
        return 'primary'
      } else {
        return 'grey'
      }
    }
  },
  created() {
    this.getNotifications();
  },
  methods: {
    getNotificationColor(isSeen) {
      if (isSeen) {
        return "blue lighten-2"
      }
      return "red"
    },
    async getNotifications() {

      const url = process.env.VUE_APP_BASE_URL + '/notification/getNotifications'

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Bildirimlere erişilemedi.'
          );
        }

        this.notifications = responseData.notifications;
      });

    },
    async markNotificationAsRead(notificationId) {
      console.log('markNotificationAsRead')
      console.log(notificationId)

      const url = process.env.VUE_APP_BASE_URL + '/notification/markNotificationAsRead'

      const data = {
        id: notificationId,
      }

      axios.post(url, data).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Bildirimlere erişilemedi.'
          );
        }

      });

    },
    clickOnNotification(notification) {
      console.log('clickOnNotification')
      console.log(notification)

      this.markNotificationAsRead(notification.id);
      notification.isSeen = true;
      console.log(notification)
      let link = ""
      const userType = localStorage.getItem('usertype');

      if (userType === "SPECIALIST") {
        link = "/sp";
      } else if (userType === "PRACTITIONER") {
        link = "/pr"
      }

      if (notification.type === 'APPOINTMENT') {
        const newLink = link + '/appointmentDetail?appointmentId=' + notification.appointmentId;
        this.$router.push(newLink);
      } else if (notification.type === 'ANNOUNCEMENT') {
        this.$router.push(link + '/dashboard');
      } else if (notification.type === 'MESSAGE') {
        this.$router.push(link + '/dashboard');
      } else {
        this.$router.push(link + '/dashboard');
      }
    },
    getIcon(notificationType) {
      console.log(notificationType)
      if (notificationType === 'APPOINTMENT') {
        return 'mdi-calendar'
      } else if (notificationType === 'ANNOUNCEMENT') {
        return 'mdi-bullhorn'
      } else if (notificationType === 'MESSAGE') {
        return 'mdi-message-text'
      }
      return 'mdi-calendar';
    }
  },

}
</script>

<style scoped>

</style>