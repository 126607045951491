<template>
  <section class="pa-10">
    <v-stepper name='createAppointmentCard'
               elevation="2" width="600px"
               outlined class="mx-auto pa-3"
               v-model="step">
      <v-stepper-header>
        <v-stepper-step
            :complete="step > 1"
            step="1">
          Hesap Bilgileri
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="step > 2"
            step="2">
          Kişisel Bilgiler
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="step > 3"
            step="3">
          Sonuç
        </v-stepper-step>

      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">

          <v-card flat>
            <v-form @submit.prevent="step = 2">
              <ValidationObserver v-slot="{ invalid }">
                <v-card-text>
                  <ValidationProvider name="E-Posta" rules="required|email" v-slot="{ errors }">
                    <v-text-field label="E-Posta" type="email" id="new-user-email"
                                  autocomplete="new-user-email"
                                  v-model.trim="request.email"
                                  :error="errors.length > 0"
                                  :error-messages="errors"
                    />
                  </ValidationProvider>
                  <ValidationProvider rules="required|min:6|password:@confirm" name="Şifre" v-slot="{ errors }">
                    <v-text-field label="Şifre"
                                  ref="password"
                                  autocomplete="new-user-password"
                                  id="password"
                                  v-model.trim="request.password"
                                  :type="showPassword ? 'text' : 'password'"
                                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="showPassword = !showPassword"
                                  :error="errors.length > 0"
                                  :error-messages="errors"/>
                  </ValidationProvider>
                  <ValidationProvider name="confirm" rules="required" v-slot="{ errors }">
                    <v-text-field label="Şifre Tekrar"
                                  ref="password"
                                  autocomplete="new-user-password-again"
                                  id="secondPassword"
                                  :type="showPasswordConfirmation ? 'text' : 'password'"
                                  :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                                  v-model.trim="secondPassword"
                                  :error="errors.length > 0"
                                  :error-messages="errors"/>
                  </ValidationProvider>

                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      type="submit"
                      :disabled="invalid">
                    İLERİ
                  </v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <ValidationObserver v-slot="{ invalid }">
            <v-card flat>
              <v-card-text>
                <ValidationProvider name="İsim" rules="required|alpha" v-slot="{ errors }">
                  <v-text-field label="İsim" type="text" id="name" v-model.trim="request.name"
                                :error="errors.length > 0"
                                :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider name="Soyisim" rules="required|alpha" v-slot="{ errors }">
                  <v-text-field label="Soyisim" type="text" id="lastName" v-model.trim="request.lastname"
                                :error="errors.length > 0"
                                :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider name="Doğum Tarihi" rules="required|beforeNow" v-slot="{ errors }">
                  <v-text-field label="Doğum Tarihi"
                                type="date"
                                id="diplomaDate"
                                :error="errors.length > 0"
                                :error-messages="errors"
                                v-model.trim="request.birthDate"
                  />
                </ValidationProvider>

                <ValidationProvider name="Telefon Numarası" rules="required|digits:10" v-slot="{ errors }">
                  <v-text-field label="Telefon Numarası" type="tel" id="phone" v-model.trim="request.phoneNumber"
                                counter="10"
                                :error="errors.length > 0"
                                :error-messages="errors"/>
                </ValidationProvider>

              </v-card-text>
              <v-card-actions>
                <v-btn @click="step = 1">
                  GERİ
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="createAccount"
                    :disabled="invalid">
                  HESABIMI OLUŞTUR
                </v-btn>
              </v-card-actions>
            </v-card>
          </ValidationObserver>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card flat>
            <v-card-text class="align-center justify-center">
              <h2 class="text-center">{{ resultTitle }}</h2>
              <h3 class="text-center">{{ resultText }}</h3>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="backButtonVisible" @click="step = 2">
                GERİ
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  @click="redirect">
                GİRİŞ
              </v-btn>
            </v-card-actions>
          </v-card>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </section>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "specialistSignup",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      step: 1,
      resultTitle: 'Hesabınız başarıyla oluşturuldu!',
      resultText: '',
      backButtonVisible: false,
      showPassword: false,
      showPasswordConfirmation: false,
      request: {
        name: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        password: '',
        birthDate: '',
      },
      activePicker: null,
      menu: false,
      password: '',
      secondPassword: '',
    }
  },
  methods: {
    async createAccount() {
      console.log('createAccount');
      console.log(this.request);

      const url = process.env.VUE_APP_BASE_URL + '/specialist/signup'

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.request)
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(
            responseData.message || 'Hatalı kullanıcı adı veya şifre. Lütfen bilgilerinizi kontrol ediniz.'
        );
      }

      if (responseData.result.success === false) {
        this.resultTitle = 'Bir hata oluştu.';
        this.resultText = responseData.result.message;
        this.backButtonVisible = true;
      } else {
        this.resultTitle = 'Hesabınız başarıyla oluşturuldu!';
        this.resultText = 'E-posta adresine gönderilen linke tıklayarak hesabınızı aktifleştirebilirsiniz.';
      }

      this.step = 3;
    },
    redirect() {
      this.$router.replace('/sp/login')
    }
  },
}
</script>

<style scoped>

</style>