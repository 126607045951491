<template>
  <v-container class="ma-10">
    <v-row justify="center">
      <v-card width="500">
        <v-card-title class="justify-center">
          {{ responseTitle }}
        </v-card-title>
        <v-card-text class="text-center">
          {{ responseText }}
        </v-card-text>
        <v-card-actions v-if="success" class="justify-center">
          <v-btn class="primary" :to="loginUrl">GİRİŞ</v-btn>
        </v-card-actions>
        <v-card-actions v-else class="justify-center">
          <v-btn class="primary" @click="sendValidationEmail">TEKRAR GÖNDER</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ValidateEmail",
  data() {
    return {
      id: null,
      userType: null,
      token: null,
      success: true,
      responseText: '',
      responseTitle: '',
      loginUrl: '/'
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    this.id = urlParams.get('id');
    this.token = urlParams.get('token');
    this.userType = urlParams.get('userType');

    if (this.userType === 'SPECIALIST') {
      this.loginUrl = '/sp/login'
    } else {
      this.loginUrl = '/pr/login'
    }

    this.validateEmail();
  },
  methods: {
    async validateEmail() {
      console.log('validateEmail');

      const url = process.env.VUE_APP_BASE_URL + '/account/validateEmail?id=' + this.id + "&token=" + this.token;

      const headersList = new Headers();
      headersList.append('Content-Type', 'application/json');
      headersList.append("userType", this.userType);

      const requestOptions = {
        headers: headersList
      };

      const response = await fetch(url, requestOptions);

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(
            responseData.message || 'E-posta adresi onaylanamadi.'
        );
      }

      this.responseText = responseData.text;
      this.responseTitle = responseData.title;
      this.success = responseData.result.success
    },
    sendValidationEmail() {
      //TODO
    }
  },
}
</script>

<style scoped>

</style>