<template>
  <section>
    <p class="text-h1 align-center justify-center mx-auto">
      Hosgeldiniz!
    </p>
  </section>
</template>

<script>
export default {
  name: "Index"
}
</script>

<style scoped>

</style>