<template>
  <v-sheet rounded elevation="1" class="red lighten-2">
    <v-row align="center">
      <v-col>
        <p class="text-no-wrap ma-2 font-weight-bold white--text">Müsait Değil</p>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "NotAvailable"
}
</script>

<style scoped>

</style>