<template>
  <v-sheet rounded elevation="1" class="blue lighten-2">
    <v-row align="center">
      <v-col :class="(removeDisabled) ? '' : 'pr-0'">
        <p class="text-no-wrap ma-2 font-weight-bold white--text pr-0" v-text="startEnd"></p>
      </v-col>
      <v-col class="ps-0" v-if="!removeDisabled">
        <v-btn icon class="my-0 mx-0 " @click="removeTimeSlot">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "TimeSlot",
  props: ['start', 'end', 'dayCode', 'removeDisabled'],
  computed: {
    startEnd() {
      return this.start + '-' + this.end;
    }
  },
  methods: {
    removeTimeSlot() {
      const payload = {start: this.start, dayCode: this.dayCode}
      this.$emit("removeTimeSlot", payload);
    }
  },
}
</script>

<style scoped>

</style>