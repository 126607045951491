<template>
  <section>
    <v-row>
      <v-col cols="8">
        <v-card class="mt-4 ml-4 pa-4 fill-height">
          <v-card-text class="align-center justify-center align-self-center align-content-center">
              <v-sheet height="450">
                <vue-webrtc ref="webrtc"
                            :roomId="appointmentId"
                            :deviceId="cameraId"
                            v-on:joined-room="logEvent"/>
              </v-sheet>

          </v-card-text>

          <v-card-actions>
            <div>
              <v-btn class="btn-primary white--text red" @click="onLeave">GÖRÜŞMEYİ SONLANDIR</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <patient-info class="mt-4 mr-4 fill-height" :patient="appointment.patient"></patient-info>
      </v-col>
    </v-row>
  </section>

</template>

<script>

import VueWebrtc from "@/pages/common/components/VueWebrtc";
import PatientInfo from "@/pages/common/components/PatientInfo";

export default {
  name: "VideoCall",
  components: {PatientInfo, VueWebrtc},
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    this.appointmentId = urlParams.get('appointmentId');

    this.getAppointmentDetails();

    this.logAppointment("JOINED_TO_CALL")
  },
  computed: {
    isSendDisabled() {
      return this.messageTextArea.length === 0;
    },
  },
  data() {
    return {
      img: null,
      appointmentId: '',
      cameraId: '',
      appointment: {},
      userId: '',
    };
  },
  methods: {
    async getAppointmentDetails() {
      console.log('getAppointmentDetails')

      const url = process.env.VUE_APP_BASE_URL + '/appointment/getAppointmentDetail?appointmentId=' + this.appointmentId;
      const token = localStorage.getItem('token')

      const headersList = new Headers();
      headersList.append("Authorization", "Bearer " + token);
      headersList.append('Content-Type', 'application/json');

      const requestOptions = {
        headers: headersList
      };
      const response = await fetch(url, requestOptions);

      const responseData = response.data;

      if (response.status !== 200) {
        throw new Error(
            responseData.message || 'Gorusme bilgilerine erisilemedi.'
        );
      }

      this.appointment = responseData.appointmentDetailResponse;
    },
    async logAppointment(logType){
      console.log('logAppointment')
      console.log(logType)

      const url = process.env.VUE_APP_BASE_URL + '/appointment/logAppointment';
      const token = localStorage.getItem('token')

      const headersList = new Headers();
      headersList.append("Authorization", "Bearer " + token);
      headersList.append('Content-Type', 'application/json');

      const requestOptions = {
        method: 'POST',
        headers: headersList,
        body: JSON.stringify({
          appointmentId: this.appointmentId,
          logType: logType
        }),
      };

      const response = await fetch(url, requestOptions);

      const responseData = response.data;

      if (response.status !== 200) {
        throw new Error(
            responseData.message || 'Log kaydi olustururken bir hata olustu.'
        );
      }
    },
    onJoin() {
      this.$refs.webrtc.join();
    },
    onLeave() {
      console.log("onLeave")
      this.logAppointment("LEFT_THE_CALL")
      this.$refs.webrtc.leave();
      this.$router.go(-1);
    },
    onError(error, stream) {
      console.log('On Error Event', error, stream);
    },
    logEvent(event) {
      console.log('Event : ', event);
    },
  }
}
</script>

<style scoped>

</style>