<template>
  <section>
    <v-row>
      <v-col>
        <p>Hastanızın tanısal sürecinde yaptığınız laboratuvar tahlilleri, tıbbi görüntüleme
          (Röntgen, CT vs.) sonuçları, klinik durumu hakkında fikir verebilecek video ve
          fotoğraflarını, daha önce uygulanan tedavi protokollerini ekleyiniz.
          Yüklediğiniz belgelerin açıklamasını “Dosya açıklaması” kısmında yapınız.
          (Ör. İlk başvuruda alınan tam kan sayımı tahlil sonucu) </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="align-self-center">
        <v-file-input
            :rules="fileUploadRules"
            accept="image/*,.pdf,video/*"
            counter
            show-size
            truncate-length="40"
            chips
            label="Dosya Seçiniz"
            v-model="file"
        ></v-file-input>
        <v-textarea
            rows="4"
            outlined
            name="examination"
            label="Dosya açıklaması"
            class="ma-2"
            counter="500"
            hint="Ör. İlk başvuruda alınan tam kan sayımı tahlil sonucu"
            no-resize
            v-model="note">
        </v-textarea>
        <v-btn @click="uploadFile" :disabled="file == null || !isFileSizeCorrect">Yükle</v-btn>
      </v-col>
      <v-col>

        <v-card
            class="mx-auto fill-height"
            max-width="400"
            tile>
          <section v-if="attachments.length > 0">
            <v-list-item two-line v-for="file in attachments" :key="file.id">
              <v-list-item-content>
                <v-row>
                  <v-col cols="1" class="mr-3 align-center justify-center">
                    <v-btn icon @click="removeFile(file)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-list-item-title>{{ file.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ file.size }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ file.note }}</v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </section>
          <section v-else class="align-center">
            <p class="text-h6">Henüz bir dosya yüklenmedi.</p>
          </section>
        </v-card>
      </v-col>
    </v-row>
    <progress-overlay :is-loading="isUploading" text="Dosyanız yükleniyor..."></progress-overlay>
  </section>
</template>

<script>
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import vueCookies from 'vue-cookies'

export default {
  name: "FileUpload",
  components: {ProgressOverlay},
  props: ['attachments'],
  data() {
    return {
      isUploading: false,
      absolute: true,
      note: '',
      file: null,
      addedFiles: [],
      fileUploadRules: [
        value => !value || value.size < 25000000 || 'Dosya boyutu 25 MB\'dan fazla olamaz.',
      ]
    }
  },
  computed: {
    isFileSizeCorrect() {
      return this.file.size < 25000000;
    }
  },
  methods: {
    removeFile(file) {
      this.addedFiles = this.addedFiles.filter(f => {
        return f.link !== file.link;
      })
      this.$emit("updateAttachments", this.addedFiles);
    },
    async uploadFile() {
      this.isUploading = true;

      const url = process.env.VUE_APP_BASE_URL + '/uploadAttachment'
      const token = vueCookies.get("token");

      const headersList = new Headers();
      headersList.append("Authorization", "Bearer " + token);

      const data = new FormData()
      data.append('file', this.file)
      data.append('note', this.note)

      const requestOptions = {
        method: 'POST',
        headers: headersList,
        body: data,
      };

      const response = await fetch(url, requestOptions);

      const responseData = await response.json();

      if (response.status !== 200) {
        this.isUploading = false;

        throw new Error(
            responseData.message || 'Dosya yuklenirken bir hata oluştu.'
        );
      }

      if (responseData.originalName.length > 30) {
        responseData.originalName = responseData.originalName.slice(0, 27) + '...'
      }

      this.addedFiles.push({
        name: responseData.originalName,
        note: responseData.note,
        size: responseData.size,
        link: responseData.fileUrl
      })

      this.file = null;
      this.note = null;

      this.$emit("updateAttachments", this.addedFiles);
      this.isUploading = false;
    }
  }
}
</script>

<style scoped>

</style>