<template>
  <v-card class="ma-4" flat>
    <v-form @submit.prevent="savePaymentInfo">
      <v-card-text>
        <v-select
            label="Bireysel/Kurumsal"
            item-text="name"
            item-value="code"
            v-model="paymentAccountType"
            :items="accountTypes"
        ></v-select>
        <v-text-field
            label="TC Kimlik No"
            counter=11
            id="tckn"
            @keypress="onlyNumber"
            v-model.trim="tckn"/>
        <v-text-field
            label="IBAN"
            prefix="TR"
            counter="32"
            id="iban"
            v-model.trim="iban"
            @keypress="onlyNumber"/>
        <v-text-field
            prefix="0"
            label="Telefon"
            counter="10"
            type="tel"
            id="phone"
            @keypress="onlyNumber"
            v-model.trim="phoneNumber"/>
        <v-autocomplete
            ref="cities"
            v-model="city"
            :items="cities"
            item-text="name"
            item-value="id"
            label="Şehir"
            :loading="citiesLoading"
            required
        ></v-autocomplete>
        <v-textarea
            rows="4"
            name="address"
            label="Adres"
            counter="500"
            no-resize
            v-model="address">
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-expand-transition>
          <v-alert width="400px" class="mx-auto" v-if="resultMessage" border="bottom"
                   :color="resultColor" dark
                   :type="resultType"
                   elevation="2">{{ resultMessage }}
          </v-alert>
        </v-expand-transition>
        <v-spacer></v-spacer>
        <v-btn
            type="submit"
            color="primary"
            class="float-right mb-4 mt-4">
          KAYDET
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import vueCookies from 'vue-cookies'

export default {
  name: "PaymentInfo",
  data() {
    return {
      accountTypes: [{name: 'Bireysel', code: 'PERSONAL'}, {name: 'Kurumsal', code: 'BUSINESS'}],
      cities: [],
      citiesLoading: false,
      resultMessage: null,
      resultColor: null,
      resultType: 'success',
      city: null,
      paymentAccountType: '',
      iban: '',
      tckn: '',
      phoneNumber: '',
      address: ''
    }
  },
  created() {
    this.getPaymentInfo();
    this.getCities();
  },
  methods: {
    async savePaymentInfo() {
      console.log('savePaymentInfo');
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/updatePaymentInfo'
      const token = vueCookies.get("token");

      const headersList = new Headers();
      headersList.append("Authorization", "Bearer " + token);
      headersList.append('Content-Type', 'application/json')
      headersList.append("Access-Control-Allow-Origin", "*")

      const data = {
        address: this.address,
        cityId: this.city,
        iban: this.iban,
        paymentAccountType: this.paymentAccountType,
        phoneNumber: this.phoneNumber,
        tckn: this.tckn
      }
      axios.post(url, data).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Ödeme bilgileri güncellenirken bir hata oluştu.'
          );
        }

        if (responseData.result.success) {
          this.resultType = 'success';
          this.personalFormDisabled = true;
          this.resultColor = 'light-blue'
        } else {
          this.resultType = 'error';
          this.resultColor = 'pink darken-1'
        }
        this.resultMessage = responseData.result.message || 'Ödeme bilgileri güncellenirken bir hata oluştu.';

        this.updateOverlay(false);

        window.setInterval(() => {
          this.resultMessage = null;
        }, 3000)
      })

    },
    async getPaymentInfo() {
      console.log('getPaymentInfo');
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/getPaymentInfo'

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Ödeme bilgilerine erisilemedi.'
          );
        }

        this.address = responseData.address;
        this.city = responseData.city.id;
        this.iban = responseData.iban;
        this.phoneNumber = responseData.phoneNumber;
        this.tckn = responseData.tckn;
        this.paymentAccountType = responseData.paymentAccountType;

        this.updateOverlay(false);
      })

    },
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
        $event.preventDefault();
      }
    },
    async getCities() {
      console.log('getCities');
      this.citiesLoading = true;
      this.cities = [];
      const url = process.env.VUE_APP_BASE_URL + '/general/getCities'

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Sehir bilgileri cekilirken hata olustu.'
          );
        }

        this.cities = responseData;
        this.citiesLoading = false;
      })

    },
    updateOverlay(isEnabled) {
      this.$emit("updateOverlay", isEnabled);
    },
  },
}
</script>

<style scoped>

</style>