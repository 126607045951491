<template>
  <div>
    <v-row>
      <v-col cols="8">
        <v-card class="mb-4">
          <v-toolbar
              flat
              class="blue lighten-2 mb-3">
            <v-icon color="white">mdi-credit-card-outline</v-icon>
            <v-toolbar-title class="white--text">
              Ücret
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                color="white"
                fab
                small
                @click="togglePriceDisabled">
              <v-icon v-if="!priceDisabled">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-form :disabled="priceDisabled" class="pa-4">
            <v-row>
              <v-col>
                <p>Danışmanlık ücretiniz 20 dakikalık görüşme için talep ettiğiniz ücrettir.
                  Belirttiğiniz ücret üzeriden %20 Hizmet Komisyonu alınacaktır.
                  Bu tutar üzerinden fatura keseceğiniz için vergilendirmeyi göz önünde bulundurmanızı öneririz.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field-money
                    v-model="price"
                    label="Talep Ettiğiniz Danışmanlık Ücreti"
                    v-bind:properties="{
                    prefix: 'TRY',
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: true,
                    placeholder: ' ',
                    }"
                    v-bind:options="{
                    locale: 'tr-TR',
                    length: 5,
                    precision: 0,
                    empty: null,
                  }"
                />
              </v-col>
              <v-col>
                <v-text-field-money
                    v-model="netEarning"
                    label="Elinize Geçecek Tutar"
                    v-bind:properties="{
                    prefix: 'TRY',
                    readonly: true,
                    disabled: true,
                    outlined: false,
                    clearable: true,
                    placeholder: ' ',
                    }"
                    v-bind:options="{
                    locale: 'tr-TR',
                    length: 5,
                    precision: 0,
                    empty: null,
                  }"
                />
              </v-col>
            </v-row>

          </v-form>
          <v-card-actions>
            <v-row>
              <v-col cols="4">
                <v-expand-transition>
                  <v-alert width="500px" class="mt-2" v-if="priceResultMessage" border="bottom"
                           :color="priceResultColor" dark
                           :type="priceResultType"
                           elevation="2">{{ priceResultMessage }}
                  </v-alert>
                </v-expand-transition>
              </v-col>
              <v-col>
                <v-btn class="primary float-right" @click="updatePrice"
                       :disabled="priceDisabled || !price ">KAYDET
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>


      <v-col cols="8">
        <v-card>
          <v-toolbar
              flat
              class="blue lighten-2 mb-3">
            <v-icon color="white">mdi-calendar</v-icon>
            <v-toolbar-title class="white--text">
              Takvim
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                color="white"
                fab
                small
                @click="toggleAvailabilityCalendar">
              <v-icon v-if="!availabilityCalendarDisabled">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-toolbar>
          <div v-for="day in days" :key="day.code" class="ma-2">
            <v-row align="center">
              <v-col>
                <v-row align="center">
                  <v-col cols="3">
                    <p class="my-auto justify-center font-weight-bold">{{ day.name }}</p>
                  </v-col>
                  <v-col cols="7">
                    <v-row>
                      <not-available
                          class="my-2 mx-2"
                          v-if="day.availabilities === null || day.availabilities.length === 0">
                      </not-available>
                      <time-slot v-for="availability in day.availabilities" v-else
                                 :key="availability.start"
                                 :end="availability.end"
                                 :start="availability.start"
                                 class="my-2 mx-2"
                                 :day-code="day.code"
                                 :removeDisabled="availabilityCalendarDisabled"
                                 @removeTimeSlot="removeTimeSlot">
                      </time-slot>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                    <v-btn class="justify-center align-center float-right"
                           color="primary"
                           :disabled="availabilityCalendarDisabled"
                           @click="openTimeSlotPicker(day)">Ekle
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </v-col>
            </v-row>
          </div>
          <v-card-actions>
            <v-row>
              <v-col cols="4">
                <v-expand-transition>
                  <v-alert width="500px" class="mt-2" v-if="resultMessage" border="bottom"
                           :color="resultColor" dark
                           :type="resultType"
                           elevation="2">{{ resultMessage }}
                  </v-alert>
                </v-expand-transition>
              </v-col>
              <v-col>
                <v-btn class="primary float-right" @click="updateAvailabilities"
                       :disabled="availabilityCalendarDisabled">KAYDET
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <time-slot-picker :current-availabilities="timeSlotPickerDialogDay.availabilities"
                      :day-code="timeSlotPickerDialogDay.code"
                      @addSlotToDay="addSlotToDay"></time-slot-picker>
  </div>
</template>

<script>
import TimeSlot from "@/pages/specialist/components/TimeSlot";
import TimeSlotPicker from "@/pages/specialist/components/TimeSlotPickerDialog";
import NotAvailable from "@/pages/specialist/components/NotAvailable";
import axios from "axios";

export default {
  name: "SpecialistCalendar",
  components: {NotAvailable, TimeSlotPicker, TimeSlot},
  data() {
    return {
      days: [],
      timeSlotPickerDialogDay: {},
      resultMessage: null,
      priceResultMessage: null,
      priceResultColor: null,
      priceResultType: null,
      resultColor: null,
      resultType: null,
      availabilityCalendarDisabled: true,
      priceDisabled: true,
      price: null,
      netEarning: null
    }
  },
  watch: {
    price(newValue) {
      this.netEarning = newValue * 0.8;
    }
  },
  created() {
    this.getAvailabilities();
    this.getPrice();
  },
  methods: {
    async getPrice() {
      console.log('getPrice')
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/getPrice'

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Ücret bilgisine erişilemedi.'
          );
        }

        this.price = responseData.price;

        this.updateOverlay(false);
      })

    },
    async updatePrice() {
      console.log('update price')

      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/updatePrice'

      const data = {price: this.price}

      axios.post(url, data).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          this.priceResultMessage = 'Ücret bilgisi güncellenirken bir hata oluştu.';

          throw new Error(
              responseData.message || 'Ücret bilgisi güncellenirken bir hata oluştu.'
          );
        }

        if (responseData.result.success) {
          this.priceResultType = 'success';
          this.priceDisabled = true;
          this.priceResultColor = 'light-blue'
        } else {
          this.priceResultType = 'error';
          this.priceResultColor = 'pink darken-1'
        }

        this.priceResultMessage = responseData.result.message || 'Ücret bilgisi güncellenirken bir hata oluştu.';

        this.updateOverlay(false);

        window.setInterval(() => {
          this.priceResultMessage = null;
        }, 3000)
      })

    },
    togglePriceDisabled() {
      this.priceDisabled = !this.priceDisabled;
    },
    toggleAvailabilityCalendar() {
      this.availabilityCalendarDisabled = !this.availabilityCalendarDisabled;
    },
    async updateAvailabilities() {
      console.log('updateAvailabilities')
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/updateAvailability'

      const availabilityMap = {};

      this.days.map(function (value) {
        availabilityMap[value.code] = value.availabilities;
      });

      const data = {availabilities: availabilityMap}

      axios.post(url, data).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          this.resultMessage = 'Takvim güncellenirken bir hata oluştu.';

          throw new Error(
              responseData.message || 'Takvim güncellenirken bir hata oluştu.'
          );
        }

        if (responseData.result.success) {
          this.resultType = 'success';
          this.personalFormDisabled = true;
          this.resultColor = 'light-blue'
        } else {
          this.resultType = 'error';
          this.resultColor = 'pink darken-1'
        }

        this.resultMessage = responseData.result.message || 'Takvim güncellenirken bir hata oluştu.';

        this.updateOverlay(false);

        window.setInterval(() => {
          this.resultMessage = null;
        }, 3000)

      })
    },
    async getAvailabilities() {
      console.log('getAvailabilities')
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/getAvailabilities'

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Takvim bilgilerine erisilemedi.'
          );
        }

        this.days = responseData.availabilities;

        this.updateOverlay(false);
      })
    },

    updateOverlay(isEnabled) {
      this.$emit("updateOverlay", isEnabled);
    },
    openTimeSlotPicker(day) {
      console.log('openTimeSlotPicker')
      console.log(day)
      this.timeSlotPickerDialogDay = day;
    },
    removeTimeSlot(payload) {
      console.log('removeTimeSlot')
      console.log(payload)
      const day = this.days.find(d => d.code === payload.dayCode);
      day.availabilities = day.availabilities.filter(a => a.start !== payload.start);
    },
    addSlotToDay(payload) {
      if (payload === null) {
        //clicked outside the dialog
        this.openTimeSlotPicker({});
        return;
      }
      const day = this.days.find(d => d.code === payload.dayCode);
      if (day.availabilities === null) {
        day.availabilities = [];
      }
      day.availabilities.push({start: payload.start, end: payload.end})
      this.openTimeSlotPicker({});
    }
  },
}
</script>

<style scoped>

</style>