<template>
  <v-flex full-width>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <announcement-alert></announcement-alert>
      <inbox-button></inbox-button>
      <notification-button></notification-button>
    </v-app-bar>
    <v-container>
      <v-navigation-drawer
          class="blue lighten-2"
          v-model="drawer"
          fixed app>
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-avatar>
              <img v-if="profilePicture != null && profilePicture !== 'null'" :src="profilePicture" alt="profile picture">
              <v-icon v-else size="54">
                mdi-account-circle
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ fullName }}</v-list-item-title>
              <v-list-item-subtitle>{{ clinicName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider></v-divider>
        <v-list>
          <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :to="item.path"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <!--<v-list-item-icon :v-if="item.alert">
                  <v-icon>mdi-alert-circle</v-icon>
                </v-list-item-icon>-->

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block @click="logout">
              <v-icon>mdi-logout</v-icon>
              ÇIKIŞ
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </v-container>
    <v-container>
      <router-view></router-view>
    </v-container>

  </v-flex>

</template>

<script>
import NotificationButton from "@/pages/practitioner/components/common/NotificationButton";
import InboxButton from "@/pages/practitioner/components/common/InboxButton";
import AnnouncementAlert from "@/pages/common/components/AnnouncementAlert";
import vueCookies from "vue-cookies";

export default {
  name: "PractitionerHome",
  components: {AnnouncementAlert, InboxButton, NotificationButton},
  data: () => ({
    drawer: true,
    group: null,
    profilePicture: null,
    fullName: '',
    clinicName:'',
    items: [
      {title: 'Yönetim Paneli', icon: 'mdi-view-dashboard-outline', path: {name: 'practitionerDashboard'}},
      {title: 'Profilim', icon: 'mdi-account-box-outline', path: {name: 'prAccount'}},
      {title: 'Yeni Vaka Oluştur', icon: 'mdi-folder-plus-outline', path: {name: 'createAppointment'}},
      {title: 'Vakalarım', icon: 'mdi-folder-outline', path: {name: 'PractitionerAppointments'}},
      {title: 'Danışmanlar', icon: 'mdi-account-supervisor', path: {name: 'specialistList'}}
    ]
  }),
  computed: {
    profileUpdateRequired() {
      return this.$store.getters["user/profileUpdateRequired"]
    }
  },
   created() {
     this.setUser();
     this.updateAlertText();
    },
    methods: {
      logout() {
        localStorage.clear();
        vueCookies.remove("token")
        this.$router.replace("login")
      },
      setUser() {
        console.log('setUser')
        this.profilePicture = localStorage.getItem('profilePhoto');
        this.fullName = localStorage.getItem('fullName');
        this.clinicName = localStorage.getItem('clinicName');
        this.userStatus = localStorage.getItem('userStatus');
      },
      updateAlertText() {
        if (this.userStatus === "MISSING_INFO") {
          console.log("MISSING_INFO")
          this.alertText = "Hesabınızın aktifleştirilmesi için lütfen profil bilgilerinizi tamamlayınız."
        } else if (this.userStatus === "AWAITING_APPROVAL") {
          this.alertText = "Hesabınız inceleme aşamasında olup en kısa sürede değerlendirilecektir."
        }
      },
    }
}
</script>

<style scoped>

</style>