<template>
  <v-dialog
      v-model="isVisible"
      persistent
      max-width="600px">
    <v-card>

      <v-card-title>
        <span class="text-h5">Randevu Talebini Reddet</span>
      </v-card-title>
      <v-form @submit.prevent="reject">

        <v-card-text>
          <v-textarea
              label="Randevuyu reddetme nedeninizi kısaca belirtiniz."
              required
              v-model.trim="rejectReason"
              hint="ör: Seçilen tarihte bir konferansa katılacağım için müsait değilim."
              :rules="[rules.blank]"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn
              text
              @click="cancel">VAZGEÇ
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="red white--text"
              text
              type="submit">
            REDDET
          </v-btn>

      </v-card-actions>
      </v-form>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RejectionDialog",
  props: ['isVisible'],
  data() {
    return {
      rejectReason: null,
      rules: {
        blank: v => v && !!v.trim() || 'Lütfen bir neden giriniz.',
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("cancelClicked");
    },
    reject() {
      console.log('reject')
      this.$emit("rejectClicked", this.rejectReason);
    }
  },
}
</script>

<style scoped>

</style>