<template>
  <v-overlay :value="isLoading">
    <v-progress-circular
        indeterminate
        size="128"
        :width="7"
        color="light-blue"
    ></v-progress-circular>
    <h3>{{text}}</h3>
  </v-overlay>
</template>

<script>
export default {
  name: "ProgressOverlay",
  props:['isLoading','text']
}
</script>

<style scoped>

</style>