import Vue from 'vue'
import VueRouter from 'vue-router'

import prLogin from "@/pages/practitioner/authentication/Login";
import spLogin from "@/pages/specialist/authentication/Login";
import prSignup from "@/pages/practitioner/authentication/PractitionerSignup";
import prSecondSignup from "@/pages/practitioner/authentication/SignupSecond";

import NotFound from "@/pages/NotFound";
import PractitionerHome from "@/pages/practitioner/PractitionerHome";
import practitionerAccount from "@/pages/practitioner/pages/PractitionerAccount";
import PractitionerDashboard from "@/pages/practitioner/pages/PractitionerDashboard";
import createAppointment from "@/pages/practitioner/pages/CreateAppointment";
import specialistList from "@/pages/practitioner/pages/SpecialistList";
import specialistHome from "@/pages/specialist/SpecialistHome";
import specialistDashboard from "@/pages/specialist/pages/SpecialistDashboard";
import specialistAccount from "@/pages/specialist/pages/SpecialistAccount";
import specialistSignup from "@/pages/specialist/authentication/SpecialistSignup";
import specialistCalendar from "@/pages/specialist/pages/SpecialistCalendar";
import specialistPaymentInfo from "@/pages/specialist/pages/SpecialistPaymentInfo";
import ValidateEmail from "@/pages/ValidateEmail";
import specialistAppointments from "@/pages/specialist/pages/SpecialistAppointments";
import practitionerAppointments from "@/pages/practitioner/pages/PractitionerAppointments";
import SpecialistAppointmentDetail from "@/pages/specialist/pages/SpecialistAppointmentDetail";
import PractitionerAppointmentDetail from "@/pages/practitioner/pages/PractitionerAppointmentDetail";
import Conversation from "@/pages/common/pages/Conversation";
import videoCall from "@/pages/common/pages/VideoCall";
import Index from "@/pages/common/pages/Index";
import forgotPassword from "@/pages/common/pages/ForgotPassword";
import resetPassword from "@/pages/common/pages/ResetPassword";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Index
    },
    {
        path: '/sp/login',
        component: spLogin
    },
    {
        path: '/pr/login',
        component: prLogin
    },
    {
        path: '/pr/signup',
        component: prSignup
    },
    {
        path: '/pr/secondSignup',
        component: prSecondSignup
    },
    {
        path: '/sp/signup',
        component: specialistSignup,
        name: 'specialistSignup'
    },
    {
        path: '/call',
        component: videoCall,
        name: 'videoCall'
    },
    {
        path: '/forgotPassword',
        component: forgotPassword,
        name: 'forgotPassword'
    },
    {
        path: '/resetPassword',
        component: resetPassword,
        name: 'resetPassword'
    },
    {
        path: '/sp',
        component: specialistHome,
        children: [{
            path: '/sp/dashboard',
            component: specialistDashboard,
            name: 'specialistDashboard',
            meta: {
                title: 'Yönetim Paneli'
            }
        },
            {
                path: '/sp/account',
                component: specialistAccount,
                name: 'specialistAccount',
                meta: {
                    title: 'Profilim'
                },

            },
            {
                path: '/sp/calendar',
                component: specialistCalendar,
                name: 'specialistCalendar',
                meta: {
                    title: 'Takvim'
                },
            },
            {
                path: '/sp/appointments',
                component: specialistAppointments,
                name: 'specialistAppointments',
                meta: {
                    title: 'Randevularım'
                },
            },
            {
                path: '/sp/appointmentDetail',
                component: SpecialistAppointmentDetail,
                name: 'specialistAppointmentDetail',
                meta: {
                    title: 'Randevu Detayı'
                },
            },
            {
                path: '/sp/payment',
                component: specialistPaymentInfo,
                name: 'specialistPaymentInfo',
                meta: {
                    title: 'Ödeme Bilgileri'
                },
            },
            {
                path: '/sp/conversation',
                component: Conversation,
                name: 'spConversation',
                meta: {
                    title: 'Randevu Mesajları'
                },
            }
        ],
    },
    {
        path: '/pr',
        component: PractitionerHome,
        children: [
            {
                path: '/pr/account',
                component: practitionerAccount,
                name: 'prAccount',
                meta: {
                    title: 'Profilim'
                }
            },
            {
                path: '/pr/dashboard',
                component: PractitionerDashboard,
                name: 'practitionerDashboard',
                meta: {
                    title: 'Yönetim Paneli'
                }
            },
            {
                path: '/pr/createAppointment',
                component: createAppointment,
                name: 'createAppointment',
                meta: {
                    title: 'Yeni Vaka Oluştur'
                }
            },
            {
                path: '/pr/appointments',
                component: practitionerAppointments,
                name: 'PractitionerAppointments',
                meta: {
                    title: 'Randevularım'
                },
            },
            {
                path: '/pr/specialistList',
                component: specialistList,
                name: 'specialistList',
                meta: {
                    title: 'Danışmanları Görüntüle'
                }
            },
            {
                path: '/pr/appointmentDetail',
                component: PractitionerAppointmentDetail,
                name: 'practitionerAppointmentDetail',
                meta: {
                    title: 'Randevu Detayı'
                },
            },
            {
                path: '/pr/conversation',
                component: Conversation,
                name: 'prConversation',
                meta: {
                    title: 'Randevu Mesajları'
                },
            }
        ]
    },
    {
        path: '/validateEmail',
        component: ValidateEmail
    },
    {
        path: '/:notFound(.*)',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
