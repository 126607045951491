<template>
  <v-card shaped width="400" class="lighten-3 ma-2" :class="isSenderComp">
    <v-card-text>
      <v-row>
        <p class="text-body-2 ma-2">
          {{ source.messageText }}
        </p>
      </v-row>
      <v-row class="float-right">
        <p class="ma-2 overline">{{ formatTime(source.time) }}</p>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MessageCard",
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default() {
        return {}
      }
    },
    userId: {
      type : String
    }
  },
  computed: {
    isSenderComp() {
      return this.userId === this.source.senderId ? 'float-right blue' : 'float-left green'
    }
  },
  methods: {
    formatTime(time) {
      const dateTime = time.split('T');
      return dateTime[1].substring(0, 5) + " " + dateTime[0];
    },
  },
}
</script>

<style scoped>

</style>