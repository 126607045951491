<template>
  <section>
    <appointment-detail @updateOverlay="updateOverlay" :appointment="appointment"
                        :appointment-id="appointmentId"></appointment-detail>
    <v-row v-if="appointment.status === 'CREATED'">
      <v-col cols="5">
        <v-btn class="red white--text ml-4" @click="openRejectDialog">REDDET</v-btn>
        <v-btn class="green  white--text float-right mr-4" @click="approveRequest">KABUL ET</v-btn>
      </v-col>
    </v-row>
    <progress-overlay :is-loading="isLoading"></progress-overlay>
    <result-dialog :result='result' :is-visible="isDialogVisible" @okayClicked="closeDialogAndRedirect"></result-dialog>
    <rejection-dialog :is-visible="rejectionDialogVisible" @cancelClicked="closeRejectDialog"
                      @rejectClicked="rejectRequest"></rejection-dialog>
  </section>
</template>

<script>
import AppointmentDetail from "@/pages/common/components/AppointmentDetail";
import ProgressOverlay from "@/pages/common/components/ProgressOverlay";
import ResultDialog from "@/pages/practitioner/components/common/ResultDialog";
import RejectionDialog from "@/pages/specialist/components/RejectionDialog";
import axios from "axios";

export default {
  name: "SpecialistAppointmentDetail",
  components: {RejectionDialog, ResultDialog, ProgressOverlay, AppointmentDetail},
  data() {
    return {
      appointmentId: null,
      isLoading: true,
      isDialogVisible: false,
      rejectionDialogVisible: false,
      result: {},
      appointment: {
        patient: {
          name: '',
          examinationNotes: '',
          anamnesis: '',
          breed: '',
          birthDate: '',
        },
        time: '',
        clinicName: '',
        practitionerFullName: '',
        status: ''
      }
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);

    this.appointmentId = urlParams.get('appointmentId');

    this.getAppointmentDetails();
  },
  methods: {
    closeRejectDialog() {
      this.rejectionDialogVisible = false;
    },
    openRejectDialog() {
      this.rejectionDialogVisible = true;
    },
    updateOverlay(isEnabled) {
      this.isLoading = isEnabled;
    },
    closeDialogAndRedirect() {
      this.isDialogVisible = false;
      this.result = null;
      this.$router.replace('appointments')
    },
    async getAppointmentDetails() {
      console.log('getAppointmentDetails')
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/appointment/getAppointmentDetail?appointmentId=' + this.appointmentId;

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Kullanici bilgilerine erisilemedi.'
          );
        }

        this.appointment = responseData.appointmentDetailResponse;
        this.updateOverlay(false);
      })

    },
    async rejectRequest(rejectReason) {
      console.log('rejectRequest')
      this.closeRejectDialog();
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/specialist/appointment/replyAppointmentRequest'

      const data = {
        appointmentId: this.appointmentId,
        isApproved: false,
        rejectReason: rejectReason,
      }

      axios.post(url, data).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Vaka durumu güncellenirken bir hata oluştu.'
          );
        }

        this.result = responseData.result;
        this.isDialogVisible = true;

        this.updateOverlay(false);
      })

    },
    async approveRequest() {
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/specialist/appointment/replyAppointmentRequest'

      const data = {
        appointmentId: this.appointmentId,
        isApproved: true,
      }

      axios.post(url, data).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Vaka durumu güncellenirken bir hata oluştu.'
          );
        }

        this.result = responseData.result;
        this.isDialogVisible = true;

        this.updateOverlay(false);
      })

    }
  },
}
</script>

<style scoped>

</style>