<template>
  <v-layout justify-center align-center class="pa-10">
    <v-card elevation="2" max-width="600" min-width="200">
      <v-toolbar
          flat
          class="blue lighten-2">
        <v-icon color="white">mdi-account</v-icon>
        <v-toolbar-title class="white--text">
          Şifremi Unuttum
        </v-toolbar-title>
      </v-toolbar>
      <v-form class="ma-4" @submit.prevent="forgotPassword">

        <v-card-text>
          <p class="text-body-1 text-center">Lütfen AsistVet'e üye olurken kullandığınız e-posta adresinizi giriniz.</p>

          <v-btn-toggle class="pb-4"
                        v-model="userType"
                        mandatory>
            <v-btn value="SPECIALIST">
              UZMAN
            </v-btn>
            <v-btn value="PRACTITIONER">
              PRATİSYEN
            </v-btn>
          </v-btn-toggle>
          <v-text-field
              label="E-Posta"
              type="email"
              id="email"
              v-model.trim="email"/>
        </v-card-text>

        <v-card-actions class="pa-3 align-center center">
          <v-expand-transition>
            <v-alert width="400px" class="mx-auto" v-if="resultMessage" border="bottom"
                     :color="resultColor" dark
                     :type="resultType"
                     elevation="2">{{ resultMessage }}
            </v-alert>
          </v-expand-transition>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary">
            İLERİ
          </v-btn>
        </v-card-actions>
      </v-form>

    </v-card>

  </v-layout>
</template>

<script>

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      resultMessage: "",
      resultColor: '',
      resultType: 'success',
      userType: '',
    }
  },
  methods: {
    async forgotPassword() {
      console.log('forgotPassword')

      if (this.email === null || this.email.length < 6) {
        this.resultMessage = 'Lütfen e-posta adresinizi giriniz.'
        this.resultType = 'error'
        return;
      } else {
        this.resultMessage = null;
        this.resultType = null
      }

      const url = process.env.VUE_APP_BASE_URL + '/auth/forgotPassword'

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'userType': this.userType
        },
        body: JSON.stringify({
          email: this.email,
        })
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(
            responseData.message || 'Bir hata oluştu.'
        );
      }

      this.resultMessage = responseData.result.message;
      this.resultType = 'success';

    }
  },
}
</script>

<style scoped>

</style>