<template>
  <v-card elevation="2" max-width="600" min-width="200px">
    <v-toolbar
        flat
        class="blue lighten-2">
      <v-icon color="white">mdi-account</v-icon>
      <v-toolbar-title class="white--text">
        Klinik Bilgileri
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
          color="white"
          fab
          small
          @click="toggleClinicForm">
        <v-icon v-if="formEnabled">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-form :disabled="!formEnabled" class="align-center mx-auto pa-3" @submit.prevent="saveClinicInfo">
      <v-card-text>
        <v-autocomplete
            ref="cities"
            v-model="city"
            :items="cities"
            item-text="name"
            item-value="id"
            label="Şehir"
            :rules="notEmpty"
            :loading="citiesLoading"
            @change="getDistricts"
            required
        ></v-autocomplete>
        <v-autocomplete
            ref="regions"
            v-model="district"
            :items="districts"
            item-text="name"
            item-value="id"
            :loading="districtsLoading"
            :rules="notEmpty"
            label="İlçe"
            required
        ></v-autocomplete>
        <v-text-field label="Klinik Adı"
                      type="text"
                      id="clinicName"
                      :rules="notEmpty"
                      v-model.trim="name"/>
        <v-text-field label="Klinik Adresi"
                      type="text"
                      id="clinicAddress"
                      :rules="notEmpty"
                      v-model.trim="address"/>
        <v-text-field label="Klinik İletişim Numarası"
                      type="text"
                      id="clinicPhone"
                      v-model.trim="phone"
                      :rules="phoneRules"
                      :prefix="'0'"
                      counter
                      maxlength="10"/>
        <v-text-field label="Klinik E-posta Adresi"
                      type="text" id="clinicEmail"
                      v-model.trim="email"
                      :rules="emailRules"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="light-green white--text" type="submit" :disabled='!saveBtnEnabled'>
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "ClinicInformation",
  data() {
    return {
      cities: [],
      districts: [],
      formEnabled: false,
      citiesLoading: false,
      districtsLoading: false,
      name: '',
      phone: '',
      address: '',
      email: '',
      city: '',
      district: '',
      emailRules: [
        v => !!v || 'Lütfen e-posta adresinizi giriniz.',
        v => /.+@.+/.test(v) || 'Lütfen geçerli bir e-posta adresi giriniz.',
      ],
      phoneRules: [
        v => !!v || 'Lütfen telefon numarası giriniz.',
        v => /^(\d{10})/.test(v) || 'Lütfen geçerli bir telefon numarası giriniz.'
      ],
      notEmpty: [
        v => !!v || 'Lütfen bu alanı doldurunuz.',
      ],
    }
  },
  created() {
    this.getClinicInfo()
  },
  computed: {
    saveBtnEnabled() {
      return this.formEnabled
          && this.email
          && this.name
          && this.phone
          && this.email
          && this.city != null
          && this.district != null
    }
  },
  methods: {
    updateOverlay(isEnabled) {
      this.$emit("updateOverlay", isEnabled);
    },
    toggleClinicForm() {
      console.log('toggleClinicForm');

      if (this.cities.length < 2) {
        this.getCities();
        this.getDistricts(this.city);
        console.log('city')
        console.log(this.city)
      }
      this.formEnabled = !this.formEnabled;
    },
    async getClinicInfo() {
      console.log('getClinicInfo');
      this.updateOverlay(true);

      const url = process.env.VUE_APP_BASE_URL + '/account/pr/getClinicInformation'

      axios.get(url).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Klinik bilgilerine erisilemedi.'
          );
        }

        //For appearance only
        this.cities.push(responseData.city);
        this.districts.push(responseData.district);

        this.name = responseData.name;
        this.address = responseData.address;
        this.phone = responseData.phoneNumber;
        this.email = responseData.email;
        this.city = responseData.city.id;
        this.district = responseData.district.id;

        this.updateOverlay(false);

      });

    },
    async saveClinicInfo() {
      console.log('saveClinicInfo');

      this.updateOverlay(true);
      const url = process.env.VUE_APP_BASE_URL + '/account/pr/updateClinicInformation'

      const data = {
        email: this.email,
        name: this.name,
        address: this.address,
        phone: this.phone,
        cityId: this.city,
        districtId: this.district,
      };

      axios.post(url, data).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Klinik bilgileri güncellenirken bir hata oluştu.'
          );
        }

        this.formEnabled = false;

        this.updateOverlay(false);
      });

    },
    async getCities() {
      console.log('getCities');
      this.citiesLoading = true;
      this.cities = [];
      const url = process.env.VUE_APP_BASE_URL + '/general/getCities'

      axios.get(url).then(response => {

        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'Sehir bilgileri cekilirken hata olustu.'
          );
        }

        this.cities = responseData;
        this.citiesLoading = false;
      })
    },
    async getDistricts(cityId) {
      console.log('getDistricts');
      console.log(cityId);

      this.districtsLoading = true;

      const url = process.env.VUE_APP_BASE_URL + '/general/getDistrictsForCity/' + cityId;

      axios.get(url).then(response => {
        const responseData = response.data;

        if (response.status !== 200) {
          throw new Error(
              responseData.message || 'İlçe bilgileri cekilirken hata olustu.'
          );
        }
        this.districts = responseData;
        this.districtsLoading = false;
      });

    }
  }
}
</script>

<style scoped>

</style>